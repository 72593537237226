import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import CheckCircle from '@material-ui/icons/CheckCircle';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useSnackbar } from 'notistack';
import ShowSnack from 'src/utils/snacks';

import api from 'src/services/data';

export default function DialogSelecionarFuncionario({
    open,
    setOpen,
    setIdFuncionario,
    setNomeFuncionario
}) {
    const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = React.useState(true);
    const [funcionarios, setFuncionarios] = React.useState([]);

    React.useEffect(() => {
        if (open) {
            api
            .get('api/acesso/listar/pessoas/funcionario', {
                headers: { Authorization: `Bearer ${sessao.lock}` }
            })
            .then(({ data }) => {
                setLoading(false);

                setFuncionarios(
                    data.map(dados => {
                        return {
                            ...dados,
                            btnAcoes: (
                                <IconButton
                                    title="Selecionar Funcionário"
                                    style={{ color: '#34b10c' }}
                                    onClick={() => {
                                        setIdFuncionario(dados.users?.id);
                                        setNomeFuncionario(dados.razao_nome);
                                        setOpen(false);
                                    }}
                                >
                                    <CheckCircle />
                                </IconButton>
                            )
                        };
                    })
                );
            })
            .catch(e => {
                ShowSnack(
                'Ocorreu um erro ao carregar os dados, entre em contato com o suporte!',
                enqueueSnackbar,
                'error'
                );
            });
        }
      }, [open]);

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            fullWidth
            maxWidth="xl"
        >
            <DialogTitle style={{ background: '#08318e' }}>
                <Typography
                    style={{
                    textAlign: 'center',
                    color: '#fff',
                    fontSize: 20,
                    fontWeight: 'bold'
                    }}
                >
                    Selecionar Funcionário
                </Typography>
            </DialogTitle>

            <DialogContent>
                {!loading ? (
                    <DataTable
                        value={funcionarios}
                        paginator
                        rows={15}
                        style={{ textAlign: 'center' }}
                    >
                        <Column
                            field="razao_nome"
                            header="Razão/Nome"
                            filter
                            filterMatchMode="contains"
                        />
                        <Column
                            field="fantasia_apelido"
                            header="Fantasia/Apelido"
                            filter
                            filterMatchMode="contains"
                        />
                        <Column field="btnAcoes" header="Ações" />
                    </DataTable>
                ) : (
                    <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    >
                        <Typography>
                            Por favor aguarde, estamos captando os funcionários!
                        </Typography>
                        <CircularProgress />
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    )
}
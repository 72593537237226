import React, { useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { useSnackbar } from 'notistack';
import ShowSnack from 'src/utils/snacks';

import api from 'src/services/data';

import './styles.css';

export default function DialogTrocaAtendente({
    open,
    setOpen,
    idAtendimento,
    setIdAtendimento,
    atendenteAtual,
    setAtendenteAtual,
    recarregar,
    setRecarregar
}) {
    const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));
    const { enqueueSnackbar } = useSnackbar();

    const [users, setUsers] = useState([]);
    const [userSelectionado, setUserSelecionado] = useState('');
    const [motivo, setMotivo] = useState('');

    const trocaAtendente = () => {
        api.post(
            'api/acesso/atualizar/troca-atendente',
            {
                atendimento: idAtendimento,
                id_autor: sessao.user_block,
                antigo_atendente: atendenteAtual.id,
                novo_atendente: userSelectionado,
                motivo
            },
            {
                headers: { Authorization: `Bearer ${sessao.lock}` }
            }
        )
        .then(({data}) => {
            ShowSnack(data.message, enqueueSnackbar);
            onClosed();
            setRecarregar(!recarregar);
        })
        .catch((e) => {
            console.log(e);
        });
    }

    const onClosed = () => {
        setOpen(false);
        setIdAtendimento('');
        setAtendenteAtual({});
        setUserSelecionado('');
        setMotivo('');
    }

    useEffect(() => {
        if (open) {
            api.get('api/acesso/lista/usuarios', {
                headers: { Authorization: `Bearer ${sessao.lock}` },
            }).then(({data}) => {
                setUsers(data);
            }).catch((e) => {
                console.log(e);
            })
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClosed();
            }}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle className="dialog_title_change">
                <Typography className="dialog_title_change_text">Trocar Atendente</Typography>
            </DialogTitle>

            <DialogContent dividers>
                <TextField
                    variant="outlined"
                    label="Atendente Atual:"
                    value={atendenteAtual.atendente}
                    fullWidth
                    disabled
                />

                <FormControl variant="outlined" fullWidth required style={{ marginTop: '0.8rem' }}>
                    <InputLabel>Novo Atendente:</InputLabel>

                    <Select
                        label="Novo Atendente:"
                        value={userSelectionado}
                        onChange={(e) => setUserSelecionado(e.target.value)}
                    >
                        <MenuItem value="" disabled>Selecione um atendente...</MenuItem>
                        {
                            users.map((user) => {
                                return user.id !== atendenteAtual.id ? (
                                    <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                                ) : null
                            })
                        }
                    </Select>
                </FormControl>

                <TextField
                    variant="outlined"
                    label="Digite o motivo..."
                    placeholder="Digite o motivo para a troca de atendente..."
                    fullWidth
                    required
                    multiline
                    rows={4}
                    rowsMax={4}
                    style={{ marginTop: '0.8rem' }}
                    value={motivo}
                    onChange={(e) => setMotivo(e.target.value)}
                />
            </DialogContent>

            <DialogActions className="dialog_actions_change">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        trocaAtendente();
                    }}
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
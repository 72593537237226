import React from 'react';

import moment from 'moment';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faFlagCheckered,
  faPauseCircle,
  faPlayCircle,
  faCheckCircle,
  faExchangeAlt
} from '@fortawesome/free-solid-svg-icons';

import api from 'src/services/data';

export default function Historico({ detalhes }) {
    const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));

    const [historico, setHistorico] = React.useState([]);

    React.useEffect(() => {
        if (detalhes){
            api.get(`api/acesso/listar/historicos_atendimento/v1/${detalhes.id}`, {
                headers: { Authorization: `Bearer ${sessao.lock}` }
            })
            .then(({data}) => {
                setHistorico(data);
            }).catch(console.log);   
        }
    }, [detalhes]);

    return (
        <>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 20,
                    marginBottom: 5
                }}
            >
                <Typography style={{ fontSize: 20 }}>
                    <FontAwesomeIcon
                        icon={faFlagCheckered}
                        style={{ color: '#08318e' }}
                    />
                </Typography>

                <Box
                    style={{
                        width: '100%',
                        padding: 10,
                        borderRadius: 4,
                        border: '2px solid #08318e'
                    }}
                >
                    <Box
                        style={{ display: 'flex',  justifyContent: 'space-between', alignItems: 'center'}}
                    >
                        <Typography
                            style={{ color: '#444', fontWeight: 'bold', fontSize: 14 }}
                        >
                            Iniciado
                        </Typography>
                        
                        <Typography
                            style={{ color: '#08318e', fontWeight: 'bold', fontSize: 12, borderBottom: '1px solid #e0e0e0' }}
                        >
                            ID:: {detalhes?.protocolo}
                        </Typography>
                    </Box>
                    
                    <Typography style={{ color: '#222', fontSize: 14 }}>
                        {moment(detalhes?.inicio).format('DD-MM-YYYY HH:mm:ss')}
                    </Typography>
                </Box>
            </Box>

            {historico?.map(dados => {
                return (
                    <>
                        {dados.antigo && (
                            <Box
                                style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 20,
                                marginBottom: 5
                                }}
                            >
                                <Typography style={{ fontSize: 20 }}>
                                    <FontAwesomeIcon
                                        icon={faExchangeAlt}
                                        style={{ color: '#ffd143', borderRadius: 6 }}
                                    />
                                </Typography>

                                <Box
                                    style={{
                                        width: '100%',
                                        padding: 10,
                                        borderRadius: 4,
                                        border: '2px solid #ffd143'
                                    }}
                                >
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography
                                            style={{
                                                color: '#444',
                                                fontWeight: 'bold',
                                                fontSize: 14
                                            }}
                                        >
                                            Troca de atendente
                                        </Typography>

                                        <Typography style={{ color: '#222', fontSize: 12 }}>
                                            {moment(dados?.created_at).format('DD-MM-YYYY HH:mm:ss')}
                                        </Typography>
                                    </Box>

                                    <Typography style={{ color: '#222', fontSize: 14 }}>
                                        {dados.antigo} para {dados.novo}
                                    </Typography>

                                    <fieldset
                                        style={{
                                        border: '1px solid #cbcbcb',
                                        borderRadius: 4,
                                        padding: 4,
                                        marginTop: 4
                                        }}
                                    >
                                        <legend
                                        style={{
                                            padding: '0px 6px',
                                            color: '#ffd143',
                                            fontSize: 12
                                        }}
                                        >
                                        Motivo:
                                        </legend>

                                        <Typography style={{ fontSize: 12, fontWeight: 500 }}>
                                            {dados?.motivo}
                                        </Typography>
                                    </fieldset>
                                </Box>
                            </Box>
                        )}

                        {dados.pausado && (
                            <Box
                                style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 20,
                                marginBottom: 5
                                }}
                            >
                                <Typography style={{ fontSize: 20 }}>
                                    <FontAwesomeIcon
                                        icon={faPauseCircle}
                                        style={{ color: '#d95c00', borderRadius: 6 }}
                                    />
                                </Typography>

                                <Box
                                    style={{
                                        width: '100%',
                                        padding: 10,
                                        borderRadius: 4,
                                        border: '2px solid #d95c00'
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: '#444',
                                            fontWeight: 'bold',
                                            fontSize: 14
                                        }}
                                    >
                                        Pausado
                                    </Typography>

                                    <Typography style={{ color: '#222', fontSize: 14 }}>
                                        {moment(dados?.pausado).format('DD-MM-YYYY HH:mm:ss')}
                                    </Typography>

                                    <fieldset
                                        style={{
                                            border: '1px solid #cbcbcb',
                                            borderRadius: 4,
                                            padding: 4,
                                            marginTop: 4
                                        }}
                                    >
                                        <legend
                                            style={{
                                                padding: '0px 6px',
                                                color: '#d95c00',
                                                fontSize: 12
                                            }}
                                        >
                                            Motivo:
                                        </legend>

                                        <Typography style={{ fontSize: 12, fontWeight: 500 }}>
                                            {dados?.motivo}
                                        </Typography>
                                    </fieldset>
                                </Box>
                            </Box>
                        )}

                        {dados.continuado && (
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    gap: 20,
                                    marginBottom: 5
                                }}
                            >
                                <Typography style={{ fontSize: 20 }}>
                                    <FontAwesomeIcon
                                        icon={faPlayCircle}
                                        style={{ color: '#007ad9', borderRadius: 6 }}
                                    />
                                </Typography>

                                <Box
                                    style={{
                                    width: '100%',
                                    padding: 10,
                                    borderRadius: 4,
                                    border: '2px solid #007ad9'
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: '#444',
                                            fontWeight: 'bold',
                                            fontSize: 14
                                        }}
                                    >
                                        Continuado
                                    </Typography>

                                    <Typography style={{ color: '#222', fontSize: 14 }}>
                                        {moment(dados?.continuado).format(
                                            'DD-MM-YYYY HH:mm:ss'
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </>
                );
            })}

            {detalhes?.fim && (
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 20,
                        marginBottom: 5
                    }}
                >
                    <Typography style={{ fontSize: 20 }}>
                        <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{ color: '#24b324' }}
                        />
                    </Typography>

                    <Box
                        style={{
                            width: '100%',
                            padding: 10,
                            borderRadius: 4,
                            border: '2px solid #24b324'
                        }}
                    >
                        <Typography
                            style={{ color: '#444', fontWeight: 'bold', fontSize: 14 }}
                        >
                            Finalizado
                        </Typography>

                        <Typography style={{ color: '#222', fontSize: 14 }}>
                            {moment(detalhes?.fim).format('DD-MM-YYYY HH:mm:ss')}
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    )
}
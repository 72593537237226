import React from 'react';
import { Dialog, DialogContent, CircularProgress } from '@material-ui/core';

export default function DialogLoading({ open }) {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )
}

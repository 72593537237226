import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  TextField,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import { AccountCircle, Visibility, VisibilityOff } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import showSnack from '../../utils/snacks';
import api from '../../services/data';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: 'url(/back_login.png)',
    backgroundSize: '100%',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    background: '#013494'
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (sessao?.lock) {
      history.push('/app/dashboard');
    }
  }, []);

  const acessar = () => {
    if (name && password) {
      setIsLoading(true);

      api
        .post('api/auth/login', {
          name,
          password
        })
        .then(({ data }) => {
          if (password === '123456') {
            localStorage.setItem('@N#P#U@', JSON.stringify({ need: 1 }));
          }
          localStorage.setItem(
            '@Account#Sessio#MM@',
            JSON.stringify({
              lock: data.access_token,
              user_block: data.data[0].user_block,
              username: data.data[0].user,
              time_session: new Date().getTime() + 3600000
            })
          );
          localStorage.setItem(
            '@Account#Remem#MM@',
            JSON.stringify({
              remem: `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.${data.data[0].ok}Ufgw&ey6Fe0jnm9E$E40Js.OiJKOiJIUzI1NiJnm9e0jn`
            })
          );
          history.push('/app');
          showSnack('Login feito com sucesso!', enqueueSnackbar);
          setName('');
          setPassword('');
        })
        .catch(() => {
          showSnack('Usuário e/ou senha incorretas!', enqueueSnackbar, 'error');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      showSnack('Preencha todos campos!', enqueueSnackbar, 'warning');
    }
  };

  const handleInput = (e) => {
    if (e.key === 'Enter') {
      document.getElementById('senha').focus();
    }
  }

  return (
    <Page className={classes.root} title="Área Restrita">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container
          maxWidth="sm"
          style={{
            border: '1px solid #ddd',
            borderRadius: 4,
            background: '#fff'
          }}
        >
          <FormControl
            variant="outlined"
            fullWidth
            style={{ marginTop: 10, marginBottom: 10 }}
            required
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Nome de Usuário:
            </InputLabel>
            <OutlinedInput
              id="usuario"
              type="text"
              endAdornment={
                <InputAdornment position="end">
                  <AccountCircle style={{ color: '#787878' }} />
                </InputAdornment>
              }
              labelWidth={150}
              value={name}
              onChange={e => {
                setName(e.target.value);
              }}
              onKeyDown={handleInput}
              disabled={isLoading}
            />
          </FormControl>

          <TextField
            id="input-with-text-normal"
            label="Senha"
            fullWidth
            variant="outlined"
            disabled={isLoading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                acessar();
              }
            }}
          />

          <Box my={2}>
            <Button
              fullWidth
              variant="contained"
              disabled={isLoading}
              style={{ background: '#013494', color: '#fff' }}
              onClick={() => {
                acessar();
              }}
            >
              { 
                isLoading
                ? (
                  <>
                    <CircularProgress style={{ width: 20, height: 20, color: '#fff', marginRight: 8 }} />
                    Conferindo suas credenciais
                  </>
                )
                : 'Acessar'
              }
            </Button>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';
import api from 'src/services/data';

import { getCnpj, getCep } from '../../../utils/consulta';
import DialogLoading from '../../../components/DialogLoading';
import TextHelper from '../../../components/TextHelper';

import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask';

export default function ClientesFormEdit() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();
  const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));
  const permissoes = JSON.parse(localStorage.getItem('@Account#Permi#MM@'));
  const [dateCad, setDateCad] = useState('');

  const [openLoading, setOpenLoading] = useState(true);

  const [tipo, setTipo] = useState('F');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [cliente, setCliente] = useState(false)
  const [fornecedor, setFornecedor] = useState(false);
  const [funcionario, setFuncionario] = useState(false);
  const [parceiro, setParceiro] = useState(false);
  const [nome, setNome] = useState('');
  const [apelido, setApelido] = useState('');
  const [telefone, setTelefone] = useState('');
  const [celular, setCelular] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCep] = useState('');
  const [rua, setRua] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [complemento, setComplemento] = useState('');
  const [contrato, setContrato] = useState('avulso');
  const [status, setStatus] = useState(1);
  const [obs, setObs] = useState('');

  const handleCnpj = (event, code = 'down') => (
    getCnpj(
      event,
      code,
      setOpenLoading,
      setNome,
      setApelido,
      setTelefone,
      setCelular,
      setEmail,
      setCep,
      setRua,
      setNumero,
      setBairro,
      setCidade,
      setEstado,
      setComplemento,
      cpfCnpj,
      ShowSnack,
      enqueueSnackbar
    )
  );

  const handleCep = (event, code = 'down') => {
    getCep(
      event,
      code,
      setRua,
      setBairro,
      setCidade,
      setEstado,
      setOpenLoading,
      ShowSnack,
      enqueueSnackbar,
      cep
    );
  }

  useEffect(() => {
    if (!permissoes.permi.edi_pessoas) {
      history.push('/app/dashboard');
      ShowSnack('Permissão não concedida', enqueueSnackbar, 'warning');
    }
  }, []);

  const salvar = () => {
    if (
        tipo &&
        nome &&
        rua &&
        numero &&
        bairro &&
        cidade &&
        estado &&
        (
          cliente ||
          funcionario ||
          fornecedor ||
          parceiro
        )
      ) {
      api
        .put(
          `/api/acesso/atualizar/pessoa/${id}`,
          {
            type_person:  tipo,
            contract:     contrato,
            register:     cpfCnpj,
            name:         nome,
            surname:      apelido,
            street:       rua,
            number:       numero,
            district:     bairro,
            complement:   complemento,
            zip_code:     cep,
            city:         cidade,
            wow:          estado,
            phone:        telefone,
            cell:         celular,
            mail:         email,
            status:       status,
            note:         obs,
            is_client:    cliente,
            is_employees: funcionario,
            is_providers: fornecedor,
            is_partner:   parceiro
          },
          {
            headers: { Authorization: `Bearer ${sessao.lock}` }
          }
        )
        .then(({ data }) => {
          if (data?.status === 400) {
            ShowSnack(data.message, enqueueSnackbar, 'warning');
            return;
          }

          ShowSnack(data.message, enqueueSnackbar);
          history.push('/app/pessoas-list');
        })
        .catch(e => {
          ShowSnack(e.response.data.message, enqueueSnackbar, 'error');
        });
    } else {
      ShowSnack(
        'Por favor, preencha todos campos!',
        enqueueSnackbar,
        'warning'
      );
    }
  };

  useEffect(() => {
    api
      .get(`/api/acesso/lista_one/pessoa/${id}`, {
        headers: { Authorization: `Bearer ${sessao.lock}` }
      })
      .then(({ data }) => {
        setTipo(data.tipo_pessoa);
        setCpfCnpj(data.cnpj_cpf);
        setCliente(data.cliente)
        setFornecedor(data.fornecedor);
        setFuncionario(data.funcionario);
        setParceiro(data.parceiro);
        setNome(data.razao_nome);
        setApelido(data.fantasia_apelido);
        setTelefone(data.telefone);
        setCelular(data.celular);
        setEmail(data.email);
        setRua(data.logradouro);
        setCep(data.cep);
        setNumero(data.numero);
        setBairro(data.bairro);
        setCidade(data.cidade);
        setEstado(data.uf);
        setComplemento(data.complemento);
        setContrato(data.contrato);
        setStatus(data.status);
        setObs(data.obs);

        let cad = new Date(data.created_at).toISOString().split('T')[0].split('-');
        cad = `${cad[2]}/${cad[1]}/${cad[0]}`;

        setDateCad(cad);

        setOpenLoading(false);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    // CNPJ ou CPF
    var options = {
      onKeyPress: function(cpf, ev, el, op) {
        var masks = ['000.000.000-000', '00.000.000/0000-00'];
        $('#cpfOuCnpjEdit').mask(cpf.length > 14 ? masks[1] : masks[0], op);
      }
    };

    $('#cpfOuCnpjEdit').length > 11
      ? $('#cpfOuCnpjEdit').mask('00.000.000/0000-00', options)
      : $('#cpfOuCnpjEdit').mask('000.000.000-00#', options);
    // Celular
    $('#celEdit').mask('(00) 0.0000-0000');
    // Telefone
    $('#telEdit').mask('(00) 0000-0000');
    // CEP
    $('#cepEdit').mask('00000-000');
  }, []);

  return (
    <>
      <Container className="area-title">
        <Grid container>
          <Grid item xs={12} className="grid-title">
            <Typography className="title">
              Formulário de atualizar pessoa
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container className="area-components">
        <Grid container>
          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              variant="outlined"
              value={dateCad}
              disabled
              label="Data de Cadastrado"
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="demo-simple-select-outlined-label">
                Tipo de pessoa:
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Tipo de pessoa:"
                value={tipo}
                onChange={e => {
                  setTipo(e.target.value);
                }}
              >
                <MenuItem value="F">Física</MenuItem>
                <MenuItem value="J">Jurídica</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="cpfOuCnpjEdit"
              label="CPF/CNPJ"
              variant="outlined"
              value={cpfCnpj}
              onChange={e => {
                setCpfCnpj(e.target.value);
              }}
              onKeyDown={handleCnpj}
            />
            <TextHelper />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <FormGroup
              row
              style={{ border: '1px solid #bbb', padding: 5, borderRadius: 4 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cliente}
                    onChange={e => {
                      setCliente(!cliente);
                    }}
                  />
                }
                label="Cliente"
              />
              
              <FormControlLabel
                control={
                  <Checkbox
                    checked={fornecedor}
                    onChange={e => {
                      setFornecedor(!fornecedor);
                    }}
                  />
                }
                label="Fornecedor"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={funcionario}
                    onChange={e => {
                      setFuncionario(!funcionario);
                    }}
                  />
                }
                label="Funcionário"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={parceiro}
                    onChange={e => {
                      setParceiro(!parceiro);
                    }}
                  />
                }
                label="Parceiro"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Nome/Razão"
              variant="outlined"
              required
              value={nome}
              onChange={e => {
                setNome(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Apelido/Fantasia"
              variant="outlined"
              value={apelido}
              onChange={e => {
                setApelido(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="telEdit"
              label="Telefone"
              variant="outlined"
              value={telefone}
              onChange={e => {
                setTelefone(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="celEdit"
              label="Celular"
              variant="outlined"
              value={celular}
              onChange={e => {
                setCelular(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              type="email"
              fullWidth
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="cepEdit"
              label="Cep"
              variant="outlined"
              value={cep}
              onChange={e => {
                setCep(e.target.value);
              }}
              onKeyDown={handleCep}
            />
            <TextHelper />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Rua"
              variant="outlined"
              value={rua}
              onChange={e => {
                setRua(e.target.value);
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Número"
              variant="outlined"
              value={numero}
              onChange={e => {
                setNumero(e.target.value);
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Bairro"
              variant="outlined"
              value={bairro}
              onChange={e => {
                setBairro(e.target.value);
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Cidade"
              variant="outlined"
              value={cidade}
              onChange={e => {
                setCidade(e.target.value);
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="UF"
              variant="outlined"
              value={estado}
              onChange={e => {
                setEstado(e.target.value);
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Complemento"
              variant="outlined"
              value={complemento}
              onChange={e => {
                setComplemento(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="demo-simple-select-outlined-label">
                Prestamento de serviço:
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Prestamento de serviço:"
                value={contrato}
                onChange={e => {
                  setContrato(e.target.value);
                }}
              >
                <MenuItem value="avulso">Avulso</MenuItem>
                <MenuItem value="contratual">Contratual</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="demo-simple-select-outlined-label">
                Status:
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Status:"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <MenuItem value={1}>Ativo</MenuItem>
                <MenuItem value={0}>Inativo</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} className="grid-campos">
            <FormLabel style={{ color: '#08318e', fontWeight: 'bold' }}>
              Observações:
            </FormLabel>
            <br />
            <TextareaAutosize
              style={{
                width: '100%',
                resize: 'none',
                padding: 10,
                fontSize: 16
              }}
              value={obs}
              onChange={e => {
                setObs(e.target.value);
              }}
              className="textareaStyle"
              placeholder="Digite as observações sobre este cliente aqui..."
              rowsMin={5}
              rowsMax={5}
            />
          </Grid>

          <Grid item xs={12} style={{ padding: 10, textAlign: 'center' }}>
            <Button className="button-register" onClick={() => salvar()}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Container>

      <DialogLoading open={openLoading} />
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Grid,
  Container,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';
import api from 'src/services/data';

import { moeda } from 'src/utils/masks';

export default function ServicosFormEdit() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();
  const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));
  const permissoes = JSON.parse(localStorage.getItem('@Account#Permi#MM@'));
  const [dateCad, setDateCad] = useState('');

  const [grupos, setGrupos] = useState([]);

  const [nome, setNome] = useState('');
  const [grupoSelecionado, setGrupoSelecionado] = useState('');
  const [custo, setCusto] = useState('');
  const [despesa, setDespesa] = useState('');
  const [lucro, setLucro] = useState('');
  const [venda, setVenda] = useState('');
  const [status, setStatus] = useState(1);

  useEffect(() => {
    if (!permissoes.permi.edi_servicos) {
      history.push('/app/dashboard');
      ShowSnack('Permissão não concedida', enqueueSnackbar, 'warning');
    }
  }, []);

  useEffect(() => {
    api
      .get('api/acesso/lista/grupos', {
        headers: { Authorization: `Bearer ${sessao.lock}` }
      })
      .then(({ data }) => {
        setGrupos(data);
      })
      .catch(e => {
        console.log(e);
      });

    api
      .get(`api/acesso/lista_one/servico/${id}`, {
        headers: { Authorization: `Bearer ${sessao.lock}` }
      })
      .then(({ data }) => {
        setNome(data.nome);
        setGrupoSelecionado(data.id_grupos);
        setCusto(data.custo_val);
        setDespesa(data.despesa_per);
        setLucro(data.lucro_per);
        setVenda(data.venda_val);
        setStatus(data.status);

        let cad = new Date(data.created_at).toISOString().split('T')[0].split('-');
        cad = `${cad[2]}/${cad[1]}/${cad[0]}`;

        setDateCad(cad);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  const calcValores = (key, value) => {
    const floatCusto = key === 'custo' ? 
          parseFloat(value ? value.replace('.', '').replace(',', '.') : 0) :
          custo ?
          parseFloat(custo.replace('.', '').replace(',', '.')) :
          parseFloat(0);

    const floatDespesa =  key === 'despesa' ? 
          parseFloat(value ? value.replace('.', '').replace(',', '.') : 0) :
          despesa ?
          parseFloat(despesa.replace('.', '').replace(',', '.')) :
          parseFloat(0);

    const floatLucro =  key === 'lucro' ? 
          parseFloat(value ? value.replace('.', '').replace(',', '.') : 0) :
          lucro ?
          parseFloat(lucro.replace('.', '').replace(',', '.')) :
          parseFloat(0);

    if (key === 'custo' || key === 'despesa' || key === 'lucro') {

      const valorDespesa = floatCusto * floatDespesa / 100;
      const valorLucro = (floatCusto - valorDespesa) * floatLucro / 100;
      const valorVenda = ( floatCusto - valorDespesa) + valorLucro;

      setVenda(moeda((valorVenda.toFixed(2)).toString()));
      return;
    }

    if (key === 'venda') {
      const floatVenda =  parseFloat(value ? value.replace('.', '').replace(',', '.') : 0);

      if (floatCusto > 0) {
        setDespesa('0,00');
        let valorFinalLucro;

        if (floatCusto > floatVenda) {
          valorFinalLucro = '0,00';
        } else {
          valorFinalLucro = ((floatCusto - floatVenda) * 100 / floatCusto).toFixed(2).toString();
        }

        setLucro(moeda(valorFinalLucro));
      } else {
        setCusto(parseFloat((floatVenda * 100) / floatCusto));
        setDespesa('0,00');
        setLucro('0,00');
      }
      return;
    }
  }

  const salvar = () => {
    if (nome && grupoSelecionado && venda && status) {
      if (parseFloat(venda ?? 0) < parseFloat(custo ?? 0)) {
        ShowSnack('O valor de venda não pode ser menor que o valor de custo.', enqueueSnackbar, 'warning');
        return;
      }

      api
        .put(
          `/api/acesso/atualizar/servico/${id}`,
          {
            grupo: grupoSelecionado,
            descricao: nome,
            custo,
            despesa,
            lucro,
            venda,
            status
          },
          {
            headers: { Authorization: `Bearer ${sessao.lock}` }
          }
        )
        .then(({ data }) => {
          ShowSnack(data.message, enqueueSnackbar);
          history.push('/app/servicos-list');
        })
        .catch(e => {
          ShowSnack(e.response.data.message, enqueueSnackbar, 'error');
        });
    } else {
      ShowSnack(
        'Por favor, preencha todos campos!',
        enqueueSnackbar,
        'warning'
      );
    }
  };

  return (
    <>
      <Container className="area-title">
        <Grid container>
          <Grid item xs={12} className="grid-title">
            <Typography className="title">
              Formulário de Atualizar Serviço
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container className="area-components">
        <Grid container>
          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              variant="outlined"
              value={dateCad}
              disabled
              label="Data de Cadastrado"
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Nome:"
              variant="outlined"
              value={nome}
              onChange={e => {
                setNome(e.target.value);
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="demo-simple-select-outlined-label">
                Grupo:
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Grupo:"
                value={grupoSelecionado}
                onChange={e => {
                  setGrupoSelecionado(e.target.value);
                }}
              >
                <MenuItem value="">
                  <em>Selecione uma opção...</em>
                </MenuItem>
                {grupos.map(value => {
                  return <MenuItem value={value.id}>{value.nome}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="custoServEdit"
              label="Custo R$:"
              variant="outlined"
              value={custo}
              onChange={e => {
                setCusto(moeda(e.target.value));
                calcValores('custo', moeda(e.target.value));
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="despesaServEdit"
              label="Despesa %:"
              variant="outlined"
              value={despesa}
              onChange={e => {
                setDespesa(moeda(e.target.value));
                calcValores('despesa', moeda(e.target.value));
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="lucroServEdit"
              label="Lucro %:"
              variant="outlined"
              value={lucro}
              onChange={e => {
                setLucro(moeda(e.target.value));
                calcValores('lucro', moeda(e.target.value));
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="vendaServEdit"
              label="Venda R$:"
              variant="outlined"
              value={venda}
              onBlur={() => {
                if (parseFloat(venda ?? 0) < parseFloat(custo ?? 0)) {
                  ShowSnack('O valor de venda não pode ser menor que o valor de custo.', enqueueSnackbar, 'warning');
                }
              }}
              onChange={e => {
                setVenda(moeda(e.target.value));
                calcValores('venda', moeda(e.target.value));
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="demo-simple-select-outlined-label">
                Status:
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Status:"
                value={status}
                onChange={() => {
                  setStatus(!status);
                }}
              >
                <MenuItem value={1}>Ativo</MenuItem>
                <MenuItem value={0}>Inativo</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} style={{ padding: 10, textAlign: 'center' }}>
            <Button className="button-register" onClick={() => salvar()}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import { Alert } from '@material-ui/lab';

import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Info from '@material-ui/icons/Info';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';
import api from '../../../services/data';

export default function UsuariosList() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));

  const [usuarios, setUsuarios] = useState([]);

  const [open, setOpen] = useState(false);
  const [openDetalhes, setOpenDetalhes] = useState(false);

  const [id, setId] = useState('');
  const [deletado, setDeletado] = useState(false);
  const [detalhes, setDetalhes] = useState([]);

  const del = () => {
    setOpen(false);
    api
      .delete(`/api/acesso/deletar/usuario/${id}`, {
        headers: { Authorization: `Bearer ${sessao.lock}` }
      })
      .then(({ data }) => {
        ShowSnack(data.message, enqueueSnackbar);
        setDeletado(!deletado);
      })
      .catch(e => {
        ShowSnack(e.response.data.message, enqueueSnackbar, 'error');
      });
  };

  useEffect(() => {
    api
      .get('api/acesso/lista/usuarios', {
        headers: { Authorization: `Bearer ${sessao.lock}` }
      })
      .then(({ data }) => {
        const results = data.map(dados => {
          return {
            pessoa: dados.pessoa.razao_nome,
            nome: dados.name,
            perfil: dados.perfil.nome,
            btnAcoes: (
              <>
                <IconButton
                  title="Mais Detalhes"
                  style={{ color: '#6420cfd9' }}
                  onClick={() => {
                    setDetalhes(dados);
                    setOpenDetalhes(true);
                  }}
                >
                  <Info />
                </IconButton>

                <IconButton
                  title="Atualizar Informações"
                  style={{ color: '#e26912' }}
                  onClick={() => {
                    history.push(`/app/usuarios-form-edit/${dados.id}`);
                  }}
                >
                  <Edit />
                </IconButton>

                <IconButton
                  title="Excluir"
                  style={{ color: '#bc0910' }}
                  onClick={() => {
                    setId(dados.id);
                    setOpen(true);
                  }}
                >
                  <Delete />
                </IconButton>
              </>
            )
          };
        });

        setUsuarios(results);
      })
      .catch(e => {
        console.log(e);
      });
  }, [deletado]);

  return (
    <>
      <Container>
        <Grid container className="area-title">
          <Grid item xs={12} className="grid-title">
            <Typography className="title">Listagem de usuários</Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button
              className="btnPrimary"
              onClick={() => {
                history.push('/app/usuarios-form');
              }}
              startIcon={<Add />}
            >
              Novo
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataTable
              value={usuarios}
              paginator
              rows={10}
              responsive
              emptyMessage="Nenhum registro cadastrado..."
              style={{ textAlign: 'center' }}

              rowHover
              currentPageReportTemplate="Mostrando {first} ao {last} de {totalRecords} registros"
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport "
              rowsPerPageOptions={[10,25,50]}
            >
              <Column field="pessoa" header="Pessoa" filter></Column>
              <Column field="nome" header="Usuário" filter></Column>
              <Column field="perfil" header="Perfil" filter></Column>
              <Column field="btnAcoes" header="Ações"></Column>
            </DataTable>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle style={{ background: '#08318e' }}>
          <Typography
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 25,
              textAlign: 'center'
            }}
          >
            Confirmar Exclução
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: 16,
              fontWeight: 555,
              marginBottom: 10
            }}
          >
            Você realmente deseja excluir estes dados?
          </Typography>
          <Alert variant="filled" severity="warning">
            Ao confirmar todos estes dados serão excluídos e não poderão ser
            recuperados.
          </Alert>
        </DialogContent>

        <DialogActions className="footer-dialog-cad-unidade">
          <Button
            onClick={() => {
              del();
            }}
            color="primary"
            className="button-register"
          >
            Confirmar
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            className="button-back"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDetalhes}
        onClose={() => {
          setOpenDetalhes(false);
        }}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ background: '#08318e' }}>
          <Typography
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 25,
              textAlign: 'center'
            }}
          >
            + Informações
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Box className="boxInfo">
            <Typography className="textInfo">Cadastrado em:</Typography>

            <Typography className="fontInfo">{moment(detalhes.created_at).format('DD/MM/YYYY HH:mm')}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Pessoa:</Typography>

            <Typography className="fontInfo">
              {detalhes.pessoa?.razao_nome}
            </Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Usuário:</Typography>
            <Typography className="fontInfo">{detalhes.name}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Perfil:</Typography>
            <Typography className="fontInfo">
              {detalhes.perfil?.nome}
            </Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">E-mail de recuperação:</Typography>
            <Typography className="fontInfo">
              {detalhes.mail_recuperacao}
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions className="footer-dialog-cad-unidade">
          <Button
            onClick={() => {
              setOpenDetalhes(false);
            }}
            color="primary"
            className="button-back"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

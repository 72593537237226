import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Container,
  Grid,
  Card,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Add, Delete, Edit, Info } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';
import api from '../../../services/data';

export default function ServicosList() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));
  const permissoes = JSON.parse(localStorage.getItem('@Account#Permi#MM@'));

  const [servicos, setServicos] = useState([]);

  const [open, setOpen] = useState(false);
  const [openDetalhes, setOpenDetalhes] = useState(false);

  const [id, setId] = useState('');
  const [deletado, setDeletado] = useState(false);
  const [detalhes, setDetalhes] = useState([]);

  const del = () => {
    setOpen(false);
    api
      .delete(`/api/acesso/deletar/servico/${id}`, {
        headers: { Authorization: `Bearer ${sessao.lock}` }
      })
      .then(({ data }) => {
        ShowSnack(data.message, enqueueSnackbar);
        setDeletado(!deletado);
      })
      .catch(e => {
        ShowSnack(e.response.data.message, enqueueSnackbar, 'error');
      });
  };

  useEffect(() => {
    if (
      permissoes.permi.vis_servicos != 1 &&
      permissoes.permi.cad_servicos != 1 &&
      permissoes.permi.edi_servicos != 1 &&
      permissoes.permi.del_servicos != 1 &&
      permissoes.permi.vis_servicos != 1
    ) {
      history.push('/app/dashboard');
      ShowSnack('Permissão não concedida', enqueueSnackbar, 'warning');
    }
  }, []);

  useEffect(() => {
    api
      .get('api/acesso/lista/servicos', {
        headers: { Authorization: `Bearer ${sessao.lock}` }
      })
      .then(({ data }) => {
        const results = data.map(dados => {
          return {
            codigo: dados.id,
            nome: dados.nome,
            valor: dados.venda_val,
            btnAcoes: (
              <>
                <IconButton
                  title="Mais Detalhes"
                  style={{ color: '#6420cfd9' }}
                  onClick={() => {
                    setDetalhes(dados);
                    setOpenDetalhes(true);
                  }}
                >
                  <Info />
                </IconButton>

                {permissoes.permi.edi_servicos === 1 && (
                  <IconButton
                    title="Atualizar Informações"
                    style={{ color: '#e26912' }}
                    onClick={() => {
                      history.push(`/app/servicos-form-edit/${dados.id}`);
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}

                {permissoes.permi.del_servicos === 1 && (
                  <IconButton
                    title="Excluir"
                    style={{ color: '#bc0910' }}
                    onClick={() => {
                      setId(dados.id);
                      setOpen(true);
                    }}
                  >
                    <Delete />
                  </IconButton>
                )}
              </>
            )
          };
        });

        setServicos(results);
      })
      .catch(e => {
        console.log(e);
      });
  }, [deletado]);

  return (
    <>
      <Container>
        <Grid container className="area-title">
          <Grid item xs={12} className="grid-title">
            <Typography className="title">Listagem de Servicos</Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container>
          {permissoes.permi.cad_servicos === 1 && (
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                className="btnPrimary"
                onClick={() => {
                  history.push('/app/servicos-form');
                }}
              >
                <Add />
                &nbsp;Novo
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            {permissoes.permi.vis_servicos === 1 ? (
              <DataTable
                value={servicos}
                paginator
                rows={10}
                responsive
                emptyMessage="Nenhum registro cadastrado..."
                style={{ textAlign: 'center' }}

                rowHover
                currentPageReportTemplate="Mostrando {first} ao {last} de {totalRecords} registros"
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport "
                rowsPerPageOptions={[10,25,50]}
              >
                <Column field="codigo" header="Código" filter></Column>
                <Column field="nome" header="Descrição" filter></Column>
                <Column field="valor" header="Valor R$" filter></Column>
                <Column field="btnAcoes" header="Ações"></Column>
              </DataTable>
            ) : (
              <Typography
                style={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  marginTop: 10,
                  fontWeight: 'bold',
                  padding: 10,
                  width: '100%',
                  background: '#d09d0f',
                  color: '#222',
                  borderRadius: 4,
                  fontSize: 20
                }}
              >
                Para editar e excluir é necessário a permissão de visualizar
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle style={{ background: '#08318e' }}>
          <Typography
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 25,
              textAlign: 'center'
            }}
          >
            Confirmar Exclução
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: 16,
              fontWeight: 555,
              marginBottom: 10
            }}
          >
            Você realmente deseja excluir estes dados?
          </Typography>
          <Alert variant="filled" severity="warning">
            Ao confirmar todos estes dados serão excluídos e não poderão ser
            recuperados.
          </Alert>
        </DialogContent>

        <DialogActions className="footer-dialog-cad-unidade">
          <Button
            onClick={() => {
              del();
            }}
            color="primary"
            className="button-register"
          >
            Confirmar
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            className="button-back"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDetalhes}
        onClose={() => {
          setOpenDetalhes(false);
        }}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ background: '#08318e' }}>
          <Typography
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 25,
              textAlign: 'center'
            }}
          >
            + Informações
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Box className="boxInfo">
            <Typography className="textInfo">Cadastrado em:</Typography>

            <Typography className="fontInfo">{moment(detalhes.created_at).format('DD/MM/YYYY H:mm')}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Código:</Typography>

            <Typography className="fontInfo">{detalhes.id}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Nome:</Typography>
            <Typography className="fontInfo">{detalhes.nome}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Grupo:</Typography>
            <Typography className="fontInfo">
              {detalhes.grupos?.nome}
            </Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Custo R$:</Typography>
            <Typography className="fontInfo">{detalhes.custo_val}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Despesa %:</Typography>
            <Typography className="fontInfo">{detalhes.despesa_per}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Lucro %:</Typography>
            <Typography className="fontInfo">{detalhes.lucro_per}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Venda R$:</Typography>
            <Typography className="fontInfo">{detalhes.venda_val}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Status:</Typography>
            <Typography className="fontInfo">
              {detalhes.status === 1 ? 'Ativo' : 'Inativo'}
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions className="footer-dialog-cad-unidade">
          <Button
            onClick={() => {
              setOpenDetalhes(false);
            }}
            color="primary"
            className="button-back"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

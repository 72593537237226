import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import Add from '@material-ui/icons/Add';
import PauseCircleFilled from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import Info from '@material-ui/icons/Info';
import CheckCircle from '@material-ui/icons/CheckCircle';
import SyncAlt from '@material-ui/icons/SyncAlt';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';
import api from '../../../services/data';
import { getTimeSuport } from '../../../utils/getTimeSuport';

import Details from '../Components/Details';
import DialogCadCliente from './components/DialogCadCliente';
import DialogTrocaAtendente from './components/DialogTrocaAtendente';
import DialogSelecionarCliente from './components/DialogSelecionarCliente';

function MeusAtendimentos() {
  const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));
  const permissoes = JSON.parse(localStorage.getItem('@Account#Permi#MM@'));
  const { enqueueSnackbar } = useSnackbar();
  const [recarregar, setRecarregar] = useState(false);
  const [recarregarClientes, setRecarregarClientes] = useState(false);
  const [atendimentosByUser, setAtendimentosByUser] = useState([]);

  const [openDetails, setOpenDetails] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [openPause, setOpenPause] = useState(false);
  const [openFinish, setOpenFinish] = useState(false);
  const [openCadCliente, setOpenCadCliente] = useState(false);
  const [openSelectCliente, setOpenSelectCliente] = useState(false);
  
  // Para troca de atendente do atendimento
  const [openTrocaAtendente, setOpenTrocaAtendente] = useState(false);
  const [atendenteAtual, setAtendenteAtual] = useState({});
  const [idAtendimento, setIdAtendimento] = useState('');

  const [time, setTime] = useState({
    color: '#007ad9',
    timeFormat: '00:00:00'
  });
  const [createdAt, setCreatedAt] = useState('');
  const [detalhesAtendimento, setDetalhesAtendimento] = useState([]);

  const [tipoAtendimento, setTipoAtendimento] = useState('');
  const [clientes, setClientes] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState('');
  const [nomeClienteSelecionado, setNomeClienteSelecionado] = useState('');
  const [motivo, setMotivo] = useState('');
  const [motivoPausa, setMotivoPausa] = useState('');
  const [resolucao, setResolucao] = useState('');
  const [protocolo, setProtocolo] = useState('');
  const [solicitante, setSolicitante] = useState('');

  const handleCloseNew = () => {
    setOpenNew(false);
    setMotivo('');
    setClienteSelecionado('');
    setNomeClienteSelecionado('');
    setTipoAtendimento('');
    setProtocolo('');
    setSolicitante('');
  };

  const handleCloseFinish = () => {
    setOpenFinish(false);
    setDetalhesAtendimento('');
    setResolucao('');
  };

  const salvar = () => {
    if (
      clienteSelecionado &&
      motivo &&
      sessao.user_block &&
      solicitante &&
      tipoAtendimento
    ) {
      if (tipoAtendimento === 'suporte') {
        if (!protocolo) {
          ShowSnack('Por favor preencha todos campos!', enqueueSnackbar, 'warning');
          return;
        }
      }

      api
        .post(
          'api/acesso/cadastrar/atendimento',
          {
            usuario: sessao.user_block,
            pessoa: clienteSelecionado,
            motivo,
            protocolo: tipoAtendimento === 'suporte' ? protocolo : undefined,
            solicitante,
            tipo_atendimento: tipoAtendimento
          },
          {
            headers: { Authorization: `Bearer ${sessao.lock}` }
          }
        )
        .then(({ data }) => {
          const verifyError = data.message.split(':');

          if (verifyError[0] === '[Error]') {
            ShowSnack(data.error.errorInfo[2], enqueueSnackbar, 'error');
          } else {
            handleCloseNew();
            setRecarregar(true);
            ShowSnack(data.message, enqueueSnackbar);
          }
        })
        .catch(e => {
          ShowSnack(
            'Ocorreu um erro ao iniciar um novo atendimento. Entre em contato com o suporte!',
            enqueueSnackbar,
            'error'
          );
        });
    } else {
      ShowSnack('Por favor preencha todos campos!', enqueueSnackbar, 'warning');
    }
  };

  const pausar = () => {
    if (motivoPausa && detalhesAtendimento) {
      api
        .post(
          'api/acesso/cadastrar/historico_atendimento',
          {
            atendimento: detalhesAtendimento?.id,
            usuario: sessao.user_block,
            motivo: motivoPausa
          },
          {
            headers: { Authorization: `Bearer ${sessao.lock}` }
          }
        )
        .then(({ data }) => {
          ShowSnack(data.message, enqueueSnackbar);
          setMotivoPausa('');
          setDetalhesAtendimento('');
          setOpenPause(false);
          setRecarregar(!recarregar);
        })
        .catch(e => {
          ShowSnack(
            'Ocorreu um erro ao Pausar o atendimento. Entre em contato com o suporte!',
            enqueueSnackbar,
            'error'
          );
        });
    } else {
      ShowSnack('Por favor preencha todos campos!', enqueueSnackbar, 'warning');
    }
  };

  const continuar = (idHistorico, idAtendimento) => {
    api
      .put(
        `api/acesso/atualizar/historico_atendimento/${idHistorico}`,
        {
          atendimento: idAtendimento
        },
        {
          headers: { Authorization: `Bearer ${sessao.lock}` }
        }
      )
      .then(({ data }) => {
        ShowSnack(data.message, enqueueSnackbar);
        setRecarregar(!recarregar);
      })
      .catch(e => {
        ShowSnack(
          'Ocorreu um erro ao Pausar o atendimento. Entre em contato com o suporte!',
          enqueueSnackbar,
          'error'
        );
      });
  };

  const finalizar = () => {
    if (detalhesAtendimento && resolucao) {
      api
        .put(
          `api/acesso/atualizar/atendimento/${detalhesAtendimento?.id}`,
          { resolucao },
          {
            headers: { Authorization: `Bearer ${sessao.lock}` }
          }
        )
        .then(({ data }) => {
          ShowSnack(data.message, enqueueSnackbar);
          handleCloseFinish();
          setRecarregar(!recarregar);
        })
        .catch(e => {
          ShowSnack(
            'Ocorreu um erro ao finalizar o atendimento. Entre em contato com o suporte!',
            enqueueSnackbar,
            'error'
          );
        });
    } else {
      ShowSnack('Por favor preencha todos campos!', enqueueSnackbar, 'warning');
    }
  };

  useEffect(() => {
    if (permissoes.permi.ematendimento_atendimentos) {
      api
        .get(`api/acesso/lista/by-user-aberto/${sessao.user_block}`, {
          headers: { Authorization: `Bearer ${sessao.lock}` }
        })
        .then(({ data }) => {
          const getAtendimentosByUser = data.reverse().map(atendimento => {
            const pausado = atendimento.historico_atendimento.filter(
              filtrar => {
                return !filtrar.continuado;
              }
            );

            const status = (
              <FormLabel
                style={{
                  background:
                    atendimento.status === 'aberto'
                      ? '#007ad9'
                      : atendimento.status === 'pausado'
                      ? '#d95c00'
                      : '#24b324',
                  color: '#fff',
                  padding: '5px 10px',
                  borderRadius: 2,
                  fontWeight: 700
                }}
              >
                {atendimento.status}
              </FormLabel>
            );

            const playPause =
              atendimento.status !== 'pausado' ? (
                <>
                  {permissoes.permi.pausar_atendimentos && (
                    <IconButton
                      onClick={() => {
                        setOpenPause(true);
                        setDetalhesAtendimento(atendimento);
                      }}
                    >
                      <PauseCircleFilled style={{ color: '#d95c00' }} />
                    </IconButton>
                  )}
                </>
              ) : (
                <>
                  {permissoes.permi.continuar_atendimentos && (
                    <IconButton
                      onClick={() => {
                        continuar(pausado[0]?.id, pausado[0]?.id_atendimento);
                      }}
                    >
                      <PlayCircleFilled style={{ color: '#007ad9' }} />
                    </IconButton>
                  )}
                </>
              );

            const dados = {
              ...atendimento,
              statusFormat: status
            };

            return {
              ...atendimento,
              statusFormat: status,
              btnAcoes: (
                <>
                  <IconButton
                    onClick={() => {
                      if (!atendimento.fim) {
                        setCreatedAt(atendimento?.inicio);
                      }
                      setOpenDetails(true);
                      setDetalhesAtendimento(dados);
                    }}
                  >
                    <Info style={{ color: '#6420cfd9' }} />
                  </IconButton>
                  
                  {
                    atendimento.status === 'aberto' && (
                      <IconButton
                        title="Trocar Atendente"
                        onClick={() => {
                          setIdAtendimento(atendimento.id);
                          setAtendenteAtual(atendimento.usuario);
                          setOpenTrocaAtendente(true);
                        }}
                      >
                        <SyncAlt style={{ color: '#ffd143' }} />
                      </IconButton>
                    )
                  }

                  {playPause}

                  {permissoes.permi.finalizar_atendimentos && atendimento.status === 'aberto' && (
                    <IconButton
                      onClick={() => {
                        setOpenFinish(true);
                        setDetalhesAtendimento(atendimento);
                      }}
                    >
                      <CheckCircle style={{ color: '#24b324' }} />
                    </IconButton>
                  )}
                </>
              )
            };
          });

          setAtendimentosByUser(getAtendimentosByUser);
        })
        .catch(() => {
          ShowSnack(
            '[Erro]: Houve um erro com o servidor. Informe ao suporte!',
            enqueueSnackbar,
            'error'
          );
        });

      setTimeout(() => {
        setRecarregar(!recarregar);
      }, 60000);
    }
  }, [recarregar]);

  useEffect(() => {
    if (createdAt) {
      setTimeout(() => {
        setTime(getTimeSuport(createdAt));
      }, 1000);
    }
  }, [createdAt, time]);

  return (
    <Grid container>
      {permissoes.permi.novo_atendimentos ? (
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            className="btnPrimary"
            startIcon={<Add />}
            onClick={() => {
              setOpenNew(true);
            }}
          >
            Novo
          </Button>
        </Grid>
      ) : null}

      {permissoes.permi.ematendimento_atendimentos ? (
        <DataTable
          paginator
          rows={10}
          responsive
          emptyMessage="Nenhum registro cadastrado..."
          style={{ textAlign: 'center' }}
          value={atendimentosByUser}
        >
          <Column
            field="protocolo"
            header="Nº de Protocolo"
            filter
            filterMatchMode="contains"
          />
          <Column
            field="pessoa.nome"
            header="Cliente"
            filter
            filterMatchMode="contains"
          />
          <Column field="statusFormat" header="Status" />
          <Column field="btnAcoes" header="Ações" />
        </DataTable>
      ) : (
        <Typography
          style={{
            textAlign: 'center',
            textTransform: 'uppercase',
            marginTop: 10,
            fontWeight: 'bold',
            padding: 10,
            width: '100%',
            background: '#d09d0f',
            color: '#222',
            borderRadius: 4,
            fontSize: 20
          }}
        >
          Sem permissão para VISUALIZAÇÃO
        </Typography>
      )}

      {/* Criar Atendimento */}
      <Dialog
        open={openNew}
        onClose={() => {
          handleCloseNew();
        }}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle style={{ background: '#08318e' }}>
          <Typography
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 25,
              textAlign: 'center'
            }}
          >
            Abrir Novo Atendimento
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ padding: 6 }}>
              <Box style={{ border: '1px solid #08318e', borderRadius: 4, padding: 10 }}>
                <FormControl component="fieldset">
                  <RadioGroup row value={tipoAtendimento} onChange={(e) => setTipoAtendimento(e.target.value)}>
                    <FormControlLabel value="suporte" control={<Radio />} label="Suporte" />
                    <FormControlLabel value="telemarketing" control={<Radio />} label="Telemarketing" />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>

            {
              tipoAtendimento && (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: 5,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignContent: 'center'
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        setOpenSelectCliente(true);
                        setClienteSelecionado('');
                        setNomeClienteSelecionado('');
                      }}
                    >
                      {nomeClienteSelecionado
                        ? nomeClienteSelecionado
                        : 'Clique para selecionar cliente...'}
                    </Button>

                    {permissoes.permi.cad_pessoas ? (
                      <IconButton
                        onClick={() => {
                          setOpenCadCliente(true);
                        }}
                        style={{ color: '#08318e' }}
                        title="Cadastrar Cliente"
                      >
                        <Add
                          style={{
                            fontSize: 28,
                            border: '1px solid #08318e',
                            borderRadius: 36
                          }}
                        />
                      </IconButton>
                    ) : null}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: 5
                    }}
                  >
                    <TextField
                      required
                      label={tipoAtendimento === 'suporte' ? "Solicitante:" : 'Contato:'}
                      variant="outlined"
                      fullWidth
                      value={solicitante}
                      onChange={e => {
                        setSolicitante(e.target.value);
                      }}
                    />
                  </Grid>
                  
                  {
                    tipoAtendimento === 'suporte' && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          padding: 5
                        }}
                      >
                        <TextField
                          required
                          label="Nº Protocolo:"
                          variant="outlined"
                          fullWidth
                          value={protocolo}
                          onChange={e => {
                            setProtocolo(e.target.value);
                          }}
                        />
                      </Grid>
                    )
                  }

                  <Grid item xs={12} style={{ padding: 5 }}>
                    <TextField
                      fullWidth
                      required
                      id="motivo"
                      label="Motivo"
                      placeholder="Digite o motivo do atendimento"
                      variant="outlined"
                      rows={5}
                      rowsMax={5}
                      multiline
                      value={motivo}
                      onChange={e => {
                        setMotivo(e.target.value);
                      }}
                    />
                  </Grid>
                </>
              )
            }
          </Grid>
        </DialogContent>

        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            className="button-register"
            onClick={() => {
              salvar();
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Pausar Atendimento */}
      <Dialog
        open={openPause}
        onClose={() => {
          setOpenPause(false);
          setMotivoPausa('');
          setDetalhesAtendimento('');
        }}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ background: '#08318e' }}>
          <Typography
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 25,
              textAlign: 'center'
            }}
          >
            Pausar Atendimento
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ padding: 5 }}>
              <TextField
                fullWidth
                required
                id="motivo"
                label="Motivo da Pausa:"
                placeholder="Digite o motivo da pausa..."
                variant="outlined"
                rows={5}
                rowsMax={5}
                multiline
                value={motivoPausa}
                onChange={e => {
                  setMotivoPausa(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            className="button-register"
            onClick={() => {
              pausar();
            }}
          >
            Pausar!
          </Button>
        </DialogActions>
      </Dialog>

      {/* Finalizar Atendimento */}
      <Dialog
        open={openFinish}
        onClose={() => {
          handleCloseFinish();
        }}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ background: '#08318e' }}>
          <Typography
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 25,
              textAlign: 'center'
            }}
          >
            Finalizar Atendimento
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ padding: 5 }}>
              <TextField
                fullWidth
                required
                id="resolucao"
                label="Resolução do atendimento:"
                placeholder="Digite a resolução do atendimento..."
                variant="outlined"
                rows={5}
                rowsMax={5}
                multiline
                value={resolucao}
                onChange={e => {
                  setResolucao(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            className="button-register"
            onClick={() => {
              finalizar();
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Selecionar Cliente */}
      <DialogSelecionarCliente
        open={openSelectCliente}
        setOpen={setOpenSelectCliente}
        setIdCliente={setClienteSelecionado}
        setNomeCliente={setNomeClienteSelecionado}
      />

      <Details
        open={openDetails}
        setOpen={setOpenDetails}
        time={time}
        setTime={setTime}
        setCreatedAt={setCreatedAt}
        detalhes={detalhesAtendimento}
        setDetalhes={setDetalhesAtendimento}
        setReload={setRecarregar}
      />

      <DialogCadCliente
        open={openCadCliente}
        setOpen={setOpenCadCliente}
        recarregar={recarregarClientes}
        setRecarregar={setRecarregarClientes}
        setIdCliente={setClienteSelecionado}
        setNomeCliente={setNomeClienteSelecionado}
      />

      <DialogTrocaAtendente
        open={openTrocaAtendente}
        setOpen={setOpenTrocaAtendente}
        idAtendimento={idAtendimento}
        setIdAtendimento={setIdAtendimento}
        atendenteAtual={atendenteAtual}
        setAtendenteAtual={setAtendenteAtual}
        recarregar={recarregar}
        setRecarregar={setRecarregar}
      />
    </Grid>
  );
}

export default MeusAtendimentos;

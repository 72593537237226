import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import CheckCircle from '@material-ui/icons/CheckCircle';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useSnackbar } from 'notistack';
import ShowSnack from 'src/utils/snacks';

import api from 'src/services/data';

export default function DialogSelecionarCliente({
    open,
    setOpen,
    idCliente,
    setIdCliente,
    setNomeCliente,
    isVersioOne = false,
    idAtendimento = null
}) {
    const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = React.useState(true);
    const [clientes, setClientes] = React.useState([]);
    const [cliente, setCliente] = React.useState();
        
    const handleUpdateInfosCliente = (receiveIdClient, receiveNameClient) => {
        setIdCliente(receiveIdClient);
        setNomeCliente(receiveNameClient);
        setOpen(false);
    }

    const handleChangeClient = (receiveIdClient, receiveNameClient) => {
        if (idAtendimento) {
            api.put(`api/acesso/atualizar/atendimento/troca_cliente/${idAtendimento}`, {
                cliente_novo: receiveIdClient
            }, {
                headers: { Authorization: `Bearer ${sessao.lock}` }
            })
            .then(() => {
                handleUpdateInfosCliente(receiveIdClient, receiveNameClient)
            })
            .catch(console.log)
        } else {
            handleUpdateInfosCliente(receiveIdClient, receiveNameClient)
        }
    }

    React.useEffect(() => {
        if (open) {
            let rota = 'api/acesso/listar/pessoas/cliente';

            if (isVersioOne) {
                rota = 'api/acesso/busca/clientes/v1';
            }

            api
            .get(rota, {
                headers: { Authorization: `Bearer ${sessao.lock}` }
            })
            .then(({ data }) => {
                const getAllClients = data.map(dados => {
                    return {
                        ...dados,
                        btnAcoes: (
                            <IconButton
                                title="Selecionar Cliente"
                                style={{ color: '#34b10c' }}
                                onClick={() => handleChangeClient(dados.id, dados.razao_nome)}
                            >
                                <CheckCircle />
                            </IconButton>
                        )
                    };
                });

                let viewClientsAvailable = getAllClients;
                let viewClientSelected = {};

                if (idCliente) {
                    viewClientsAvailable = getAllClients.filter(fil => fil.id !== idCliente);
                    viewClientSelected = getAllClients.filter(fil => fil.id === idCliente)[0];
                }

                setClientes(viewClientsAvailable);
                setCliente(viewClientSelected);
                setLoading(false);
            })
            .catch(() => {
                ShowSnack(
                'Ocorreu um erro ao carregar os dados, entre em contato com o suporte!',
                enqueueSnackbar,
                'error'
                );
            });
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            fullWidth
            maxWidth="xl"
        >
            <DialogTitle style={{ background: '#08318e' }}>
                <Typography
                    style={{
                    textAlign: 'center',
                    color: '#fff',
                    fontSize: 20,
                    fontWeight: 'bold'
                    }}
                >
                    Selecionar Cliente
                </Typography>
            </DialogTitle>

            <DialogContent>
                {!loading ? (
                    <>
                        {
                            cliente && (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                    marginBottom={4}
                                >
                                    <Typography variant="subtitle1">Nome/Razão: <b>{cliente.razao_nome}</b></Typography>
                                    <Typography variant="subtitle1">Apelido/Fantasia: <b>{cliente.fantasia_apelido ?? '-'}</b></Typography>
                                </Box>
                            )
                        }

                        <DataTable
                            value={clientes}
                            paginator
                            rows={15}
                            style={{ textAlign: 'center' }}
                        >
                            <Column
                                field="razao_nome"
                                header="Razão/Nome"
                                filter
                                filterMatchMode="contains"
                            />
                            <Column
                                field="fantasia_apelido"
                                header="Fantasia/Apelido"
                                filter
                                filterMatchMode="contains"
                            />
                            <Column field="btnAcoes" header="Ações" />
                        </DataTable>
                    </>
                ) : (
                    <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    >
                        <Typography>
                            Por favor aguarde, estamos captando os clientes!
                        </Typography>
                        <CircularProgress />
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    )
}
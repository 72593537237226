import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ShowSnack from '../../utils/snacks';
import api from '../../services/data';

function AtualizarDados() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));

  const [emailRecuperacao, setEmailRecuperacao] = useState('');
  const [nome, setNome] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmSenha, setConfirmSenha] = useState('');

  useEffect(() => {
    api
      .get(`api/acesso/lista_one/usuario/${sessao.user_block}`, {
        headers: { Authorization: `Bearer ${sessao.lock}` }
      })
      .then(({ data }) => {
        setNome(data.name);
        setEmailRecuperacao(data.mail_recuperacao);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  const salvar = () => {
    if (emailRecuperacao && nome) {
      let continua = false;

      if (senha || confirmSenha) {
        if (senha === confirmSenha) {
          continua = true;
        } else {
          ShowSnack('Senhas não são iguais !', enqueueSnackbar, 'warning');
        }
      } else {
        continua = true;
      }

      if (continua) {
        api
          .put(
            `/api/acesso/atualizar/usuario/${sessao.user_block}`,
            {
              emailRecuperacao,
              nome,
              senha
            },
            {
              headers: { Authorization: `Bearer ${sessao.lock}` }
            }
          )
          .then(({ data }) => {
            const verifyError = data.message.split(':');

            if (verifyError[0] === '[Error]') {
              ShowSnack(data.error.errorInfo[2], enqueueSnackbar, 'error');
            } else {
              localStorage.removeItem('@N#P#U@');
              localStorage.removeItem('@Account#Sessio#MM@');
              localStorage.removeItem('@Account#Remem#MM@');
              localStorage.removeItem('@Account#Permi#MM@');
              ShowSnack(data.message, enqueueSnackbar);
              history.push('/');
            }
          })
          .catch(e => {
            ShowSnack(e.response.data.message, enqueueSnackbar, 'error');
          });
      }
    } else {
      ShowSnack(
        'Por favor, preencha todos campos!',
        enqueueSnackbar,
        'warning'
      );
    }
  };

  return (
    <Container>
      <Grid container style={{ background: '#fff' }}>
        <Grid item xs={12} className="grid-campos">
          <Typography
            style={{
              textAlign: 'center',
              padding: 10,
              fontSize: 20,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: '#013494'
            }}
          >
            Atualizar Dados
          </Typography>

          <Typography
            style={{
              textAlign: 'center',
              fontSize: 14,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: '#565656',
              borderBottom: '1px solid #013494'
            }}
          >
            <Warning style={{ fontSize: 16 }} /> Após a realização da
            atualização dos dados, será necessário efetuar o login o novamente.
          </Typography>
        </Grid>

        <Grid item xs={12}></Grid>

        <Grid item xs={12} md={6} className="grid-campos">
          <TextField
            type="email"
            fullWidth
            id="outlined-basic"
            label="Email de Recuperação:"
            variant="outlined"
            value={emailRecuperacao}
            onChange={e => {
              setEmailRecuperacao(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} className="grid-campos">
          <TextField
            fullWidth
            id="outlined-basic"
            label="Nome de Usuário:"
            variant="outlined"
            value={nome}
            onChange={e => {
              setNome(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} className="grid-campos">
          <TextField
            fullWidth
            id="outlined-basic"
            label="Digite a senha:"
            variant="outlined"
            value={senha}
            onChange={e => {
              setSenha(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} className="grid-campos">
          <TextField
            fullWidth
            id="outlined-basic"
            label="Re-Digite a senha:"
            variant="outlined"
            value={confirmSenha}
            onChange={e => {
              setConfirmSenha(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} style={{ padding: 10, textAlign: 'center' }}>
          <Button
            className="button-register"
            onClick={() => {
              salvar();
            }}
          >
            Atualizar
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AtualizarDados;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';
import api from 'src/services/data';

import { moeda } from 'src/utils/masks';

export default function ProdutosForm() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));
  const permissoes = JSON.parse(localStorage.getItem('@Account#Permi#MM@'));
  const [dateCad, setDateCad] = useState('');

  const [fornecedores, setFornecedores] = useState([]);
  const [subgrupos, setSubGrupos] = useState([]);

  const [nome, setNome] = useState('');
  const [fornecedorSelecionado, setFornecedorSelecionado] = useState('');
  const [subGrupoSelecionado, setSubGrupoSelecionado] = useState('');
  const [unidade, setUnidade] = useState('');
  const [custo, setCusto] = useState('');
  const [despesa, setDespesa] = useState('');
  const [lucro, setLucro] = useState('');
  const [venda, setVenda] = useState('');
  const [status, setStatus] = useState(true);

  const calcValores = (key, value) => {
    const floatCusto = key === 'custo' ? 
          parseFloat(value ? value.replace('.', '').replace(',', '.') : 0) :
          custo ?
          parseFloat(custo.replace('.', '').replace(',', '.')) :
          parseFloat(0);

    const floatDespesa =  key === 'despesa' ? 
          parseFloat(value ? value.replace('.', '').replace(',', '.') : 0) :
          despesa ?
          parseFloat(despesa.replace('.', '').replace(',', '.')) :
          parseFloat(0);

    const floatLucro =  key === 'lucro' ? 
          parseFloat(value ? value.replace('.', '').replace(',', '.') : 0) :
          lucro ?
          parseFloat(lucro.replace('.', '').replace(',', '.')) :
          parseFloat(0);

    if (key === 'custo' || key === 'despesa' || key === 'lucro') {

      const valorDespesa = floatCusto * floatDespesa / 100;
      const valorLucro = (floatCusto + valorDespesa) * floatLucro / 100;
      const valorVenda = ( floatCusto + valorDespesa) + valorLucro;

      setVenda(moeda((valorVenda.toFixed(2)).toString()));
      return;
    }

    if (key === 'venda') {
      const floatVenda =  parseFloat(value ? value.replace('.', '').replace(',', '.') : 0);

      if (floatCusto > 0) {
        setDespesa('0,00');
        let valorFinalLucro;

        if (floatCusto > floatVenda) {
          valorFinalLucro = '0,00';
        } else {
          valorFinalLucro = ((floatCusto - floatVenda) * 100 / floatCusto).toFixed(2).toString();
        }

        setLucro(moeda(valorFinalLucro));
      } else {
        setCusto(parseFloat((floatVenda * 100) / floatCusto));
        setDespesa('0,00');
        setLucro('0,00');
      }
      return;
    }
  }

  useEffect(() => {
    if (!permissoes.permi.cad_produtos) {
      history.push('/app/dashboard');
      ShowSnack('Permissão não concedida', enqueueSnackbar, 'warning');
    }
  }, []);

  useEffect(() => {
    api
      .get('api/acesso/listar/pessoas/fornecedor', {
        headers: { Authorization: `Bearer ${sessao.lock}` }
      })
      .then(({ data }) => {
        setFornecedores(data);
      })
      .catch(e => {
        console.log(e);
      });

    api
      .get('api/acesso/lista/subgrupops', {
        headers: { Authorization: `Bearer ${sessao.lock}` }
      })
      .then(({ data }) => {
        setSubGrupos(data);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    let cad = new Date().toISOString().split('T')[0].split('-');
    cad = `${cad[2]}/${cad[1]}/${cad[0]}`;

    setDateCad(cad);
  }, []);

  const salvar = () => {
    if (
      nome &&
      fornecedorSelecionado &&
      subGrupoSelecionado &&
      unidade &&
      venda &&
      status
    ) {
      if (parseFloat(venda ?? 0) < parseFloat(custo ?? 0)) {
        ShowSnack('O valor de venda não pode ser menor que o valor de custo.', enqueueSnackbar, 'warning');
        return;
      }

      api
        .post(
          '/api/acesso/cadastrar/produto',
          {
            pessoa: fornecedorSelecionado,
            subgrupo: subGrupoSelecionado,
            descricao: nome,
            unidade,
            custo,
            despesa,
            lucro,
            venda,
            status
          },
          {
            headers: { Authorization: `Bearer ${sessao.lock}` }
          }
        )
        .then(({ data }) => {
          ShowSnack(data.message, enqueueSnackbar);
          history.push('/app/produtos-list');
        })
        .catch(e => {
          ShowSnack(e.response.data.message, enqueueSnackbar, 'error');
        });
    } else {
      ShowSnack(
        'Por favor, preencha todos campos!',
        enqueueSnackbar,
        'warning'
      );
    }
  };

  return (
    <>
      <Container className="area-title">
        <Grid container>
          <Grid item xs={12} className="grid-title">
            <Typography className="title">
              Formulário de cadastro de produto
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container className="area-components">
        <Grid container>
          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              variant="outlined"
              value={dateCad}
              disabled
              label="Data de Cadastrado"
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Nome:"
              variant="outlined"
              value={nome}
              onChange={e => {
                setNome(e.target.value);
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="demo-simple-select-outlined-label">
                Fornecedor:
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Fornecedor:"
                value={fornecedorSelecionado}
                onChange={e => {
                  setFornecedorSelecionado(e.target.value);
                }}
              >
                <MenuItem value="">
                  <em>Selecione uma opção...</em>
                </MenuItem>
                {fornecedores?.map(value => {
                  return <MenuItem key={value.id} value={value.id}>{value.razao_nome}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="demo-simple-select-outlined-label">
                Sub-Grupo:
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Sub-Grupo:"
                value={subGrupoSelecionado}
                onChange={e => {
                  setSubGrupoSelecionado(e.target.value);
                }}
              >
                <MenuItem value="">
                  <em>Selecione uma opção...</em>
                </MenuItem>
                {subgrupos?.map(value => {
                  return <MenuItem key={value.id} value={value.id}>{value.nome}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="demo-simple-select-outlined-label">
                Unidade:
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Unidade:"
                value={unidade}
                onChange={e => {
                  setUnidade(e.target.value);
                }}
              >
                <MenuItem value="UN">UN</MenuItem>
                <MenuItem value="MT">MT</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="custo"
              label="Custo R$:"
              variant="outlined"
              value={custo}
              onChange={e => {
                setCusto(moeda(e.target.value));
                calcValores('custo', moeda(e.target.value));
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="despesa"
              label="Despesa %:"
              variant="outlined"
              value={despesa}
              onChange={e => {
                setDespesa(moeda(e.target.value));
                calcValores('despesa', moeda(e.target.value));
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="lucro"
              label="Lucro %:"
              variant="outlined"
              value={lucro}
              onChange={e => {
                setLucro(moeda(e.target.value));
                calcValores('lucro', moeda(e.target.value));
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <TextField
              fullWidth
              id="venda"
              label="Venda R$:"
              variant="outlined"
              value={venda}
              onBlur={() => {
                if (parseFloat(venda ?? 0) < parseFloat(custo ?? 0)) {
                  ShowSnack('O valor de venda não pode ser menor que o valor de custo.', enqueueSnackbar, 'warning');
                }
              }}
              onChange={e => {
                setVenda(moeda(e.target.value));
                calcValores('venda', moeda(e.target.value));
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6} className="grid-campos">
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="demo-simple-select-outlined-label">
                Status:
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Status:"
                value={status}
                onChange={() => {
                  setStatus(!status);
                }}
              >
                <MenuItem value={true}>Ativo</MenuItem>
                <MenuItem value={false}>Inativo</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} style={{ padding: 10, textAlign: 'center' }}>
            <Button className="button-register" onClick={() => salvar()}>
              Cadastrar
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

import axios from 'axios';

const AMB_DEV = 'PRODUCTION'; // DEV OR PRODUCTION

const api = axios.create({
  baseURL: AMB_DEV === 'DEV' ?
  'http://127.0.0.1:8000' :
  'https://maximummanagerapiweb.systemsautomacao.com.br/public/'
});

export default api;

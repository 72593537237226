import React, { useEffect, useState } from 'react';
import {
  useHistory,
  NavLink as RouterLink,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  makeStyles,
  ListItem,
  Collapse
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
  Home,
  List as ListClient,
  LabelImportant,
  LocalMall,
  SwapHoriz,
  Description,
  PowerSettingsNew,
  Settings
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';
import api from '../../../services/data';
import backRemember from 'src/utils/backRemember';

import Logout from '../components/Logout';

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  button: {
    '&:hover': {
      color: '#08318e',
      background: 'transparent'
    },
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  buttonActived: {
    '&:hover': {
      color: '#08318e',
      background: 'transparent'
    },
    color: '#08318e',
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  subButton: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  subIcon: {
    marginRight: theme.spacing(1),
    fontSize: 15
  },
  title: {
    marginRight: 'auto',
    fontSize: 15
  },
  subTitle: {
    marginRight: 'auto'
  },
  nested: {
    paddingLeft: theme.spacing(4),
    marginTop: -24
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));
  const remember = JSON.parse(localStorage.getItem('@Account#Remem#MM@'));

  const [openCadastros, setOpenCadastros] = useState(false);
  const [openEstoque, setOpenEstoque] = useState(false);
  const [openMovimentacoes, setOpenMovimentacoes] = useState(false);
  const [openRelatorio, setOpenRelatorio] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);

  const [pathname, setPathname] = useState('');

  const [pessoas, setPessoas] = useState(false);
  const [usuarios, setUsuarios] = useState(false);
  const [perfil, setPerfil] = useState(false);

  const [grupop, setGrupop] = useState(false);
  const [subgrupop, setSubgrupop] = useState(false);
  const [produtos, setProdutos] = useState(false);
  const [grupos, setGrupos] = useState(false);
  const [servicos, setServicos] = useState(false);

  const [atendimentos, setAtendimentos] = useState(false);

  const [relatorioAtendimentos, setRelatorioAtendimentos] = useState(false);

  const permissionBlocked = () => {
    localStorage.removeItem('@N#P#U@');
    localStorage.removeItem('@Account#Sessio#MM@');
    localStorage.removeItem('@Account#Remem#MM@');
    localStorage.removeItem('@Account#Permi#MM@');
    history.push('/');
    ShowSnack('Necessário realizar login!', enqueueSnackbar, 'info');
  };

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  useEffect(() => {
    if (remember.remem) {
      const identify = backRemember(remember.remem);

      api
        .get(`api/acesso/lista_one/perfil/${identify}`, {
          headers: { Authorization: `Bearer ${sessao.lock}` }
        })
        .then(({ data }) => {
          localStorage.setItem(
            '@Account#Permi#MM@',
            JSON.stringify({ permi: data })
          );

          if (
            data.vis_pessoas > 0 ||
            data.cad_pessoas > 0 ||
            data.edi_pessoas > 0 ||
            data.del_pessoas > 0
          ) {
            setPessoas(true);
          } else {
            setPessoas(false);
          }

          if (
            data.vis_usuarios > 0 ||
            data.cad_usuarios > 0 ||
            data.edi_usuarios > 0 ||
            data.del_usuarios > 0
          ) {
            setUsuarios(true);
          } else {
            setUsuarios(false);
          }

          if (
            data.vis_perfis > 0 ||
            data.cad_perfis > 0 ||
            data.edi_perfis > 0 ||
            data.del_perfis > 0
          ) {
            setPerfil(true);
          } else {
            setPerfil(false);
          }

          if (
            data.vis_grupops > 0 ||
            data.cad_grupops > 0 ||
            data.edi_grupops > 0 ||
            data.del_grupops > 0
          ) {
            setGrupop(true);
          } else {
            setGrupop(false);
          }

          if (
            data.vis_subgrupops > 0 ||
            data.cad_subgrupops > 0 ||
            data.edi_subgrupops > 0 ||
            data.del_subgrupops > 0
          ) {
            setSubgrupop(true);
          } else {
            setSubgrupop(false);
          }

          if (
            data.vis_produtos > 0 ||
            data.cad_produtos > 0 ||
            data.edi_produtos > 0 ||
            data.del_produtos > 0
          ) {
            setProdutos(true);
          } else {
            setProdutos(false);
          }

          if (
            data.vis_gruposs > 0 ||
            data.cad_gruposs > 0 ||
            data.edi_gruposs > 0 ||
            data.del_gruposs > 0
          ) {
            setGrupos(true);
          } else {
            setGrupos(false);
          }

          if (
            data.vis_servicos > 0 ||
            data.cad_servicos > 0 ||
            data.edi_servicos > 0 ||
            data.del_servicos > 0
          ) {
            setServicos(true);
          } else {
            setServicos(false);
          }

          if (
            data.novo_atendimentos > 0 ||
            data.pausar_atendimentos > 0 ||
            data.continuar_atendimentos > 0 ||
            data.finalizar_atendimentos > 0 ||
            data.ematendimento_atendimentos > 0 ||
            data.finalizados_atendimentos > 0 ||
            data.acompanhar_atendimentos > 0
          ) {
            setAtendimentos(true);
          } else {
            setAtendimentos(false);
          }

          if (data.relatorio_atendimentos > 0) {
            setRelatorioAtendimentos(true);
          } else {
            setRelatorioAtendimentos(false);
          }
        })
        .catch(e => {
          permissionBlocked();
        });
    } else {
      permissionBlocked();
    }
  }, [location]);

  const menu = (etapa, valor) => {
    if (valor) {
      if (etapa === 'cadastros') {
        setOpenCadastros(true);
        setOpenEstoque(false);
        setOpenMovimentacoes(false);
        setOpenRelatorio(false);
      } else if (etapa === 'estoque') {
        setOpenCadastros(false);
        setOpenEstoque(true);
        setOpenMovimentacoes(false);
        setOpenRelatorio(false);
      } else if (etapa === 'movimentacoes') {
        setOpenCadastros(false);
        setOpenEstoque(false);
        setOpenMovimentacoes(true);
        setOpenRelatorio(false);
      } else if (etapa === 'relatorios') {
        setOpenCadastros(false);
        setOpenEstoque(false);
        setOpenMovimentacoes(false);
        setOpenRelatorio(true);
      }
    } else {
      if (etapa === 'cadastros') {
        setOpenCadastros(false);
      } else if (etapa === 'estoque') {
        setOpenEstoque(false);
      } else if (etapa === 'movimentacoes') {
        setOpenMovimentacoes(false);
      } else if (etapa === 'relatorios') {
        setOpenRelatorio(false);
      }
    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={2}>
        <List>
          <ListItem>
            <Button
              className={
                pathname === '/app/dashboard'
                  ? classes.buttonActived
                  : classes.button
              }
              component={RouterLink}
              to="/app/dashboard"
            >
              <Home className={classes.icon} size="20" />
              <span className={classes.title}>Início</span>
            </Button>
          </ListItem>

          {/* Cadastros */}
          <ListItem
            onClick={() => {
              menu('cadastros', !openCadastros);
            }}
          >
            <Button
              className={
                openCadastros ||
                pathname === '/app/pessoas-list' ||
                pathname === '/app/pessoas-form' ||
                pathname === '/app/usuarios-list' ||
                pathname === '/app/usuarios-form' ||
                pathname === '/app/perfis-list' ||
                pathname === '/app/perfis-form'
                  ? classes.buttonActived
                  : classes.button
              }
            >
              <ListClient className={classes.icon} size="20" />
              <span className={classes.title}>Cadastros</span>
              {openCadastros ||
              pathname === '/app/pessoas-list' ||
              pathname === '/app/pessoas-form' ||
              pathname === '/app/usuarios-list' ||
              pathname === '/app/usuarios-form' ||
              pathname === '/app/perfis-list' ||
              pathname === '/app/perfis-form' ? (
                <ExpandLess className={classes.icon} size="20" />
              ) : (
                <ExpandMore className={classes.icon} size="20" />
              )}
            </Button>
          </ListItem>
          <Collapse
            in={
              openCadastros ||
              pathname === '/app/pessoas-list' ||
              pathname === '/app/pessoas-form' ||
              pathname === '/app/usuarios-list' ||
              pathname === '/app/usuarios-form' ||
              pathname === '/app/perfis-list' ||
              pathname === '/app/perfis-form'
            }
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {pessoas && (
                <ListItem className={classes.nested}>
                  <Button
                    className={
                      pathname === '/app/pessoas-list' ||
                      pathname === '/app/pessoas-form'
                        ? classes.buttonActived
                        : classes.button
                    }
                    component={RouterLink}
                    to="/app/pessoas-list"
                  >
                    <LabelImportant className={classes.subIcon} />
                    <span className={classes.subTitle}>Pessoas</span>
                  </Button>
                </ListItem>
              )}

              {usuarios && (
                <ListItem className={classes.nested}>
                  <Button
                    className={
                      pathname === '/app/usuarios-list' ||
                      pathname === '/app/usuarios-form'
                        ? classes.buttonActived
                        : classes.button
                    }
                    component={RouterLink}
                    to="/app/usuarios-list"
                  >
                    <LabelImportant className={classes.subIcon} />
                    <span className={classes.subTitle}>Usuários</span>
                  </Button>
                </ListItem>
              )}

              {perfil && (
                <ListItem className={classes.nested}>
                  <Button
                    className={
                      pathname === '/app/perfis-list' ||
                      pathname === '/app/perfis-form'
                        ? classes.buttonActived
                        : classes.button
                    }
                    component={RouterLink}
                    to="/app/perfis-list"
                  >
                    <LabelImportant className={classes.subIcon} />
                    <span className={classes.subTitle}>Perfis</span>
                  </Button>
                </ListItem>
              )}
            </List>
          </Collapse>

          {/* Estoque */}
          <ListItem
            onClick={() => {
              menu('estoque', !openEstoque);
            }}
          >
            <Button
              className={
                openEstoque ||
                pathname === '/app/grupops-list' ||
                pathname === '/app/subgrupops-list' ||
                pathname === '/app/produtos-list' ||
                pathname === '/app/produtos-form' ||
                pathname === '/app/gruposs-list' ||
                pathname === '/app/servicos-list' ||
                pathname === '/app/servicos-form'
                  ? classes.buttonActived
                  : classes.button
              }
            >
              <LocalMall className={classes.icon} />
              <span className={classes.title}>Estoque</span>
              {openEstoque ||
              pathname === '/app/grupops-list' ||
              pathname === '/app/subgrupops-list' ||
              pathname === '/app/produtos-list' ||
              pathname === '/app/produtos-form' ||
              pathname === '/app/gruposs-list' ||
              pathname === '/app/servicos-list' ||
              pathname === '/app/servicos-form' ? (
                <ExpandLess className={classes.icon} size="20" />
              ) : (
                <ExpandMore className={classes.icon} size="20" />
              )}
            </Button>
          </ListItem>
          <Collapse
            in={
              openEstoque ||
              pathname === '/app/grupops-list' ||
              pathname === '/app/subgrupops-list' ||
              pathname === '/app/produtos-list' ||
              pathname === '/app/produtos-form' ||
              pathname === '/app/gruposs-list' ||
              pathname === '/app/servicos-list' ||
              pathname === '/app/servicos-form'
            }
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {grupop && (
                <ListItem className={classes.nested}>
                  <Button
                    className={
                      pathname === '/app/grupops-list'
                        ? classes.buttonActived
                        : classes.button
                    }
                    component={RouterLink}
                    to="/app/grupops-list"
                  >
                    <LabelImportant className={classes.subIcon} />
                    <span className={classes.subTitle}>Grupos - Produto</span>
                  </Button>
                </ListItem>
              )}

              {subgrupop && (
                <ListItem className={classes.nested}>
                  <Button
                    className={
                      pathname === '/app/subgrupops-list'
                        ? classes.buttonActived
                        : classes.button
                    }
                    component={RouterLink}
                    to="/app/subgrupops-list"
                  >
                    <LabelImportant className={classes.subIcon} />
                    <span className={classes.subTitle}>Sub-Grupos</span>
                  </Button>
                </ListItem>
              )}

              {produtos && (
                <ListItem className={classes.nested}>
                  <Button
                    className={
                      pathname === '/app/produtos-list' ||
                      pathname === '/app/produtos-form'
                        ? classes.buttonActived
                        : classes.button
                    }
                    component={RouterLink}
                    to="/app/produtos-list"
                  >
                    <LabelImportant className={classes.subIcon} />
                    <span className={classes.subTitle}>Produtos</span>
                  </Button>
                </ListItem>
              )}

              {grupos && (
                <ListItem className={classes.nested}>
                  <Button
                    className={
                      pathname === '/app/gruposs-list'
                        ? classes.buttonActived
                        : classes.button
                    }
                    component={RouterLink}
                    to="/app/gruposs-list"
                  >
                    <LabelImportant className={classes.subIcon} />
                    <span className={classes.subTitle}>Grupos - Serviço</span>
                  </Button>
                </ListItem>
              )}

              {servicos && (
                <ListItem className={classes.nested}>
                  <Button
                    className={
                      pathname === '/app/servicos-list' ||
                      pathname === '/app/servicos-form'
                        ? classes.buttonActived
                        : classes.button
                    }
                    component={RouterLink}
                    to="/app/servicos-list"
                  >
                    <LabelImportant className={classes.subIcon} />
                    <span className={classes.subTitle}>Serviços</span>
                  </Button>
                </ListItem>
              )}
            </List>
          </Collapse>

          {/* Movimentações */}
          {atendimentos && (
            <>
              <ListItem
                onClick={() => {
                  menu('movimentacoes', !openMovimentacoes);
                }}
              >
                <Button
                  className={
                    pathname === '/app/atendimentos' || openMovimentacoes
                      ? classes.buttonActived
                      : classes.button
                  }
                >
                  <SwapHoriz className={classes.icon} />
                  <span className={classes.title}>Movimentações</span>
                  {pathname === '/app/atendimentos' || openMovimentacoes ? (
                    <ExpandLess className={classes.icon} size="20" />
                  ) : (
                    <ExpandMore className={classes.icon} size="20" />
                  )}
                </Button>
              </ListItem>
              <Collapse
                in={pathname === '/app/atendimentos' || openMovimentacoes}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItem className={classes.nested}>
                    <Button
                      className={
                        pathname === '/app/atendimentos'
                          ? classes.buttonActived
                          : classes.button
                      }
                      component={RouterLink}
                      to="/app/atendimentos"
                    >
                      <LabelImportant className={classes.subIcon} />
                      <span className={classes.subTitle}>Atendimentos</span>
                    </Button>
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}

          {/* Relatórios */}
          <ListItem
            onClick={() => {
              menu('relatorios', !openRelatorio);
            }}
          >
            <Button
              className={
                pathname === '/app/relatorio/atendimentos' || openRelatorio
                  ? classes.buttonActived
                  : classes.button
              }
            >
              <Description className={classes.icon} />
              <span className={classes.title}>Relatórios</span>
              {pathname === '/app/relatorio/atendimentos' || openRelatorio ? (
                <ExpandLess className={classes.icon} size="20" />
              ) : (
                <ExpandMore className={classes.icon} size="20" />
              )}
            </Button>
          </ListItem>
          <Collapse
            in={pathname === '/app/relatorio/atendimentos' || openRelatorio}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {relatorioAtendimentos && (
                <ListItem className={classes.nested}>
                  <Button
                    className={
                      pathname === '/app/relatorio/atendimentos'
                        ? classes.buttonActived
                        : classes.button
                    }
                    component={RouterLink}
                    to="/app/relatorio/atendimentos"
                  >
                    <LabelImportant className={classes.subIcon} />
                    <span className={classes.subTitle}>Atendimentos</span>
                  </Button>
                </ListItem>
              )}
            </List>

            <List component="div" disablePadding>
              {relatorioAtendimentos && (
                <ListItem className={classes.nested}>
                  <Button
                    className={
                      pathname === '/app/relatorio/atendimentos-v1'
                        ? classes.buttonActived
                        : classes.button
                    }
                    component={RouterLink}
                    to="/app/relatorio/atendimentos-v1"
                  >
                    <LabelImportant className={classes.subIcon} />
                    <span className={classes.subTitle}>Atendimentos V1</span>
                  </Button>
                </ListItem>
              )}
            </List>
          </Collapse>

          {/* Opções Globais */}
          <Hidden lgUp>
            <ListItem>
              <Button
                className={classes.button}
                component={RouterLink}
                to="/app/atualizar-dados"
              >
                <Settings className={classes.icon} />
                <span className={classes.title}>Configurações</span>
              </Button>
            </ListItem>

            <ListItem
              onClick={() => {
                setOpenLogout(true);
              }}
            >
              <Button className={classes.button}>
                <PowerSettingsNew className={classes.icon} />
                <span className={classes.title}>Sair</span>
              </Button>
            </ListItem>
          </Hidden>
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>

      <Logout open={openLogout} setOpen={setOpenLogout} />
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;

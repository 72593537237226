import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Container,
  Grid,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TextField
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Add, Delete, Edit, Info } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';
import api from '../../../services/data';
import { InputText } from 'primereact/inputtext';

export default function GrupopsList() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));
  const permissoes = JSON.parse(localStorage.getItem('@Account#Permi#MM@'));
  const [dateCad, setDateCad] = useState('');
  const [dateCadEdit, setDateCadEdit] = useState('');

  const [grupos, setGrupos] = useState([]);

  const [openNovo, setOpenNovo] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);
  const [openDeletar, setOpenDeletar] = useState(false);
  const [atualizou, setAtualizou] = useState(false);

  const [id, setId] = useState('');
  const [nome, setNome] = useState('');

  useEffect(() => {
    if (
      permissoes.permi.vis_grupops != 1 &&
      permissoes.permi.cad_grupops != 1 &&
      permissoes.permi.edi_grupops != 1 &&
      permissoes.permi.del_grupops != 1 &&
      permissoes.permi.vis_grupops != 1
    ) {
      history.push('/app/dashboard');
      ShowSnack('Permissão não concedida', enqueueSnackbar, 'warning');
    }
  }, []);

  useEffect(() => {
    api
      .get('api/acesso/lista/grupops', {
        headers: { Authorization: `Bearer ${sessao.lock}` }
      })
      .then(({ data }) => {
        const results = data.map(dados => {
          return {
            nome: dados.nome,
            dateCreated: moment(dados.created_at).format('DD/MM/YYY HH:mm'),
            btnAcoes: (
              <>
                {permissoes.permi.edi_grupops === 1 && (
                  <IconButton
                    title="Atualizar Informações"
                    style={{ color: '#e26912' }}
                    onClick={() => {
                      setId(dados.id);
                      setNome(dados.nome);
                      
                      let cad = new Date(dados.created_at).toISOString().split('T')[0].split('-');
                      cad = `${cad[2]}/${cad[1]}/${cad[0]}`;

                      setDateCadEdit(cad);

                      setOpenEditar(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}

                {permissoes.permi.del_grupops === 1 && (
                  <IconButton
                    title="Excluir"
                    style={{ color: '#bc0910' }}
                    onClick={() => {
                      setId(dados.id);
                      setOpenDeletar(true);
                    }}
                  >
                    <Delete />
                  </IconButton>
                )}
              </>
            )
          };
        });

        setGrupos(results);
      })
      .catch(e => {
        console.log(e);
      });
  }, [atualizou]);

  useEffect(() => {
    let cad = new Date().toISOString().split('T')[0].split('-');
    cad = `${cad[2]}/${cad[1]}/${cad[0]}`;

    setDateCad(cad);
  }, []);

  const novo = () => {
    if (nome) {
      api
        .post(
          'api/acesso/cadastrar/grupop',
          { descricao: nome },
          {
            headers: { Authorization: `Bearer ${sessao.lock}` }
          }
        )
        .then(({ data }) => {
          ShowSnack(data.message, enqueueSnackbar);
          setAtualizou(!atualizou);
          setOpenNovo(false);
          setNome('');
        })
        .catch(e => {
          ShowSnack(e.response.data.message, enqueueSnackbar, 'error');
        });
    } else {
      ShowSnack(
        'Por favor, preencha todos campos!',
        enqueueSnackbar,
        'warning'
      );
    }
  };

  const up = () => {
    if (id && nome) {
      api
        .put(
          `api/acesso/atualizar/grupop/${id}`,
          { descricao: nome },
          {
            headers: { Authorization: `Bearer ${sessao.lock}` }
          }
        )
        .then(({ data }) => {
          ShowSnack(data.message, enqueueSnackbar);
          setAtualizou(!atualizou);
          setOpenEditar(false);
          setNome('');
          setId('');
        })
        .catch(e => {
          ShowSnack(e.response.data.message, enqueueSnackbar, 'error');
        });
    } else {
      ShowSnack(
        'Por favor, preencha todos campos!',
        enqueueSnackbar,
        'warning'
      );
    }
  };

  const del = () => {
    setOpenDeletar(false);
    api
      .delete(`/api/acesso/deletar/grupop/${id}`, {
        headers: { Authorization: `Bearer ${sessao.lock}` }
      })
      .then(({ data }) => {
        ShowSnack(data.message, enqueueSnackbar);
        setAtualizou(!atualizou);
      })
      .catch(e => {
        ShowSnack(e.response.data.message, enqueueSnackbar, 'error');
      });
  };

  return (
    <>
      <Container>
        <Grid container className="area-title">
          <Grid item xs={12} className="grid-title">
            <Typography className="title">Listagem de Grupos</Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container>
          {permissoes.permi.cad_grupops === 1 && (
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                className="btnPrimary"
                onClick={() => {
                  setOpenNovo(true);
                }}
              >
                <Add />
                &nbsp;Novo
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            {permissoes.permi.vis_grupops === 1 ? (
              <DataTable
                value={grupos}
                paginator
                rows={10}
                responsive
                emptyMessage="Nenhum registro cadastrado..."
                style={{ textAlign: 'center' }}

                rowHover
                currentPageReportTemplate="Mostrando {first} ao {last} de {totalRecords} registros"
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport "
                rowsPerPageOptions={[10,25,50]}
              >
                <Column field="dateCreated" header="Criado em" filter/>
                <Column field="nome" header="Nome" filter />
                <Column field="btnAcoes" header="Ações" />
              </DataTable>
            ) : (
              <Typography
                style={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  marginTop: 10,
                  fontWeight: 'bold',
                  padding: 10,
                  width: '100%',
                  background: '#d09d0f',
                  color: '#222',
                  borderRadius: 4,
                  fontSize: 20
                }}
              >
                Para editar e excluir é necessário a permissão de visualizar
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>

      {/* Novo */}
      <Dialog
        open={openNovo}
        onClose={() => {
          setOpenNovo(false);
          setNome('');
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ background: '#08318e' }}>
          <Typography
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 25,
              textAlign: 'center'
            }}
          >
            Novo Grupo de Produto
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} style={{ padding: 4 }}>
              <TextField
                fullWidth
                variant="outlined"
                value={dateCad}
                disabled
                label="Data de Cadastrado"
              />
            </Grid>

            <Grid item xs={12} style={{ padding: 4 }}>
              <TextField
                id="outlined-basic"
                label="Descricao"
                variant="outlined"
                fullWidth
                value={nome}
                onChange={e => {
                  setNome(e.target.value);
                }}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className="footer-dialog-cad-unidade">
          <Button
            onClick={() => {
              novo();
            }}
            color="primary"
            className="button-register"
          >
            Confirmar
          </Button>
          <Button
            onClick={() => {
              setOpenNovo(false);
              setNome('');
            }}
            color="primary"
            className="button-back"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Editar */}
      <Dialog
        open={openEditar}
        onClose={() => {
          setOpenEditar(false);
          setNome('');
          setId('');
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ background: '#08318e' }}>
          <Typography
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 25,
              textAlign: 'center'
            }}
          >
            Editar Grupo de Produto
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} style={{ padding: 4 }}>
              <TextField
                fullWidth
                variant="outlined"
                value={dateCadEdit}
                disabled
                label="Data de Cadastrado"
              />
            </Grid>

            <Grid item xs={12} style={{ padding: 4 }}>
              <TextField
                id="outlined-basic"
                label="Descricao"
                variant="outlined"
                fullWidth
                value={nome}
                onChange={e => {
                  setNome(e.target.value);
                }}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className="footer-dialog-cad-unidade">
          <Button
            onClick={() => {
              up();
            }}
            color="primary"
            className="button-register"
          >
            Confirmar
          </Button>
          <Button
            onClick={() => {
              setOpenEditar(false);
              setNome('');
              setId('');
            }}
            color="primary"
            className="button-back"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Deletar */}
      <Dialog
        open={openDeletar}
        onClose={() => {
          setOpenDeletar(false);
        }}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle style={{ background: '#08318e' }}>
          <Typography
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 25,
              textAlign: 'center'
            }}
          >
            Confirmar Exclução
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: 16,
              fontWeight: 555,
              marginBottom: 10
            }}
          >
            Você realmente deseja excluir estes dados?
          </Typography>
          <Alert variant="filled" severity="warning">
            Ao confirmar todos estes dados serão excluídos e não poderão ser
            recuperados.
          </Alert>
        </DialogContent>

        <DialogActions className="footer-dialog-cad-unidade">
          <Button
            onClick={() => {
              del();
            }}
            color="primary"
            className="button-register"
          >
            Confirmar
          </Button>
          <Button
            onClick={() => {
              setOpenDeletar(false);
            }}
            color="primary"
            className="button-back"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Edit } from '@material-ui/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faFlagCheckered,
  faPauseCircle,
  faPlayCircle,
  faCheckCircle,
  faExchangeAlt
} from '@fortawesome/free-solid-svg-icons';

import DialogSelecionarCliente from '../MeusAtendimentos/components/DialogSelecionarCliente';

import moment from 'moment';
import { getTimeSuportFinished } from 'src/utils/getTimeSuportFinished';

const useStyles = makeStyles(() => ({
  areaTitle: {
    background: '#08318e'
  },
  title: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 25,
    textAlign: 'center'
  },
  areaTime: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  titleTime: {
    color: '#08318e',
    fontWeight: 'bold',
    fontSize: 14
  },
  time: {
    width: '100%',
    padding: 10,
    borderRadius: 4,
    color: '#f2f4f5',
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  areaDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  detailsTitle: {
    color: '#08318e',
    fontWeight: 'bold',
    fontSize: 14
  },
  details: {
    marginTop: 10,
    color: '#444',
    fontSize: 14,
    fontWeight: 'bold'
  },
  areaMotivo: {
    border: '1px solid #08318e',
    borderRadius: 4,
    padding: 8,
    width: '100%'
  },
  motivo: {
    fontSize: 14,
    fontWeight: 700,
    color: '#444'
  }
}));

function Details({
  open,
  setOpen,
  time = {},
  setTime = () => {},
  setCreatedAt = () => {},
  detalhes,
  setDetalhes,
  setReload = () => {}
}) {
  const permissoes = JSON.parse(localStorage.getItem('@Account#Permi#MM@'));

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const timeFinished = getTimeSuportFinished(detalhes.inicio, detalhes?.fim);
  const [mudouCliente, setMudouCliente] = useState(false);

  const [openChangeClient, setOpenChangeClient] = useState(false);
  const [idCliente, setIdCliente] = useState('');
  const [nomeCliente, setNomeCliente] = useState('');
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (open && idCliente) {
      setMudouCliente(true);
    }
  }, [open, idCliente]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setTime({ color: '#007ad9', timeFormat: '00:00:00' });
        setCreatedAt('');
        setDetalhes([]);
        setValue(0);

        if (mudouCliente) {
          setReload(oldValue => !oldValue);
        }
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.areaTitle}>
        <Typography className={classes.title}>+ Informações</Typography>
      </DialogTitle>

      <Grid container>
        <Grid item xs={12}>
          <Paper style={{ background: '#cecece' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="secondary"
              centered
            >
              <Tab
                label="Detalhes"
                id="simple-tab-0"
                aria-controls="simple-tabpanel-0"
              />
              <Tab
                label="Histórico"
                id="simple-tab-1"
                aria-controls="simple-tabpanel-1"
              />
            </Tabs>
          </Paper>
        </Grid>
      </Grid>

      <DialogContent>
        <TabPanel value={value} index={0}>
          <Box className={classes.areaTime}>
            <Typography className={classes.titleTime}>
              Tempo de Atendimento:
            </Typography>
            <Typography
              className={classes.time}
              style={{
                background: detalhes?.fim ? timeFinished.color : time.color
              }}
            >
              {detalhes?.fim ? timeFinished.timeFormat : time.timeFormat}
            </Typography>
          </Box>

          <Box className={classes.areaDetails}>
            <Typography className={classes.detailsTitle}>Protocolo:</Typography>
            <Typography className={classes.details}>
              {detalhes?.protocolo}
            </Typography>
          </Box>

          <Box className={classes.areaDetails}>
            <Typography className={classes.detailsTitle}>Atendente:</Typography>
            <Typography className={classes.details}>
              {detalhes?.usuario?.atendente}
            </Typography>
          </Box>

          <Box className={classes.areaDetails}>
            <Typography className={classes.detailsTitle}>Cliente:</Typography>
            <Typography className={classes.details}>
              {
                (!detalhes?.fim || permissoes.permi.acompanhar_atendimentos)  && (
                  <IconButton
                    title="Trocar Cliente"
                    onClick={() => setOpenChangeClient(true)}
                  >
                    <Edit style={{ color: '#ff8100' }}/>
                  </IconButton>
                )
              }

              {nomeCliente ? nomeCliente : detalhes?.pessoa?.nome}
            </Typography>
          </Box>

          <Box className={classes.areaDetails}>
            <Typography className={classes.detailsTitle}>
              Solicitante:
            </Typography>
            <Typography className={classes.details}>
              {detalhes.solicitante ? detalhes.solicitante : '-'}
            </Typography>
          </Box>

          <Box className={classes.areaDetails}>
            <Typography className={classes.detailsTitle}>Status:</Typography>
            <Typography className={classes.details}>
              {detalhes?.statusFormat}
            </Typography>
          </Box>

          <Box className={classes.areaTime}>
            <Typography className={classes.titleTime}>Motivo:</Typography>
            <Box className={classes.areaMotivo}>
              <Typography className={classes.motivo}>
                {detalhes?.motivo}
              </Typography>
            </Box>
          </Box>
          {detalhes.resolucao && (
            <Box className={classes.areaTime} style={{ marginTop: 10 }}>
              <Typography className={classes.titleTime}>Resolucao:</Typography>
              <Box className={classes.areaMotivo}>
                <Typography className={classes.motivo}>
                  {detalhes?.resolucao}
                </Typography>
              </Box>
            </Box>
          )}
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 20,
              marginBottom: 5
            }}
          >
            <Typography style={{ fontSize: 20 }}>
              <FontAwesomeIcon
                icon={faFlagCheckered}
                style={{ color: '#08318e' }}
              />
            </Typography>

            <Box
              style={{
                width: '100%',
                padding: 10,
                borderRadius: 4,
                border: '2px solid #08318e'
              }}
            >
              <Box
                style={{ display: 'flex',  justifyContent: 'space-between', alignItems: 'center'}}
              >
                <Typography
                  style={{ color: '#444', fontWeight: 'bold', fontSize: 14 }}
                >
                  Iniciado
                </Typography>
                
                <Typography
                  style={{ color: '#08318e', fontWeight: 'bold', fontSize: 12, borderBottom: '1px solid #e0e0e0' }}
                >
                  ID:: {detalhes?.protocolo}
                </Typography>
              </Box>
              
              <Typography style={{ color: '#222', fontSize: 14 }}>
                {moment(detalhes?.inicio).format('DD-MM-YYYY HH:mm:ss')}
              </Typography>
            </Box>
          </Box>

          {detalhes?.historico_atendimento?.map(dados => {
            return (
              <>
                {
                  dados.atendente_antigo && (
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 20,
                        marginBottom: 5
                      }}
                    >
                      <Typography style={{ fontSize: 20 }}>
                        <FontAwesomeIcon
                          icon={faExchangeAlt}
                          style={{ color: '#ffd143', borderRadius: 6 }}
                        />
                      </Typography>

                      <Box
                        style={{
                          width: '100%',
                          padding: 10,
                          borderRadius: 4,
                          border: '2px solid #ffd143'
                        }}
                      >
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography
                            style={{
                              color: '#444',
                              fontWeight: 'bold',
                              fontSize: 14
                            }}
                          >
                            Troca de atendente
                          </Typography>

                          <Typography style={{ color: '#222', fontSize: 12 }}>
                            {moment(dados?.created_at).format('DD-MM-YYYY HH:mm:ss')}
                          </Typography>
                        </Box>

                        <Typography style={{ color: '#222', fontSize: 14 }}>
                            {dados.atendente_antigo?.pessoa?.razao_nome} para {dados.atendente_novo?.pessoa?.razao_nome}
                        </Typography>

                        <fieldset
                          style={{
                            border: '1px solid #cbcbcb',
                            borderRadius: 4,
                            padding: 4,
                            marginTop: 4
                          }}
                        >
                          <legend
                            style={{
                              padding: '0px 6px',
                              color: '#ffd143',
                              fontSize: 12
                            }}
                          >
                            Motivo:
                          </legend>

                          <Typography style={{ fontSize: 12, fontWeight: 500 }}>
                            {dados?.motivo}
                          </Typography>
                        </fieldset>
                      </Box>
                    </Box>
                  )
                }

                {
                  dados.pausado && (
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 20,
                        marginBottom: 5
                      }}
                    >
                      <Typography style={{ fontSize: 20 }}>
                        <FontAwesomeIcon
                          icon={faPauseCircle}
                          style={{ color: '#d95c00', borderRadius: 6 }}
                        />
                      </Typography>

                      <Box
                        style={{
                          width: '100%',
                          padding: 10,
                          borderRadius: 4,
                          border: '2px solid #d95c00'
                        }}
                      >
                        <Typography
                          style={{
                            color: '#444',
                            fontWeight: 'bold',
                            fontSize: 14
                          }}
                        >
                          Pausado
                        </Typography>
                        <Typography style={{ color: '#222', fontSize: 14 }}>
                          {moment(dados?.pausado).format('DD-MM-YYYY HH:mm:ss')}
                        </Typography>

                        <fieldset
                          style={{
                            border: '1px solid #cbcbcb',
                            borderRadius: 4,
                            padding: 4,
                            marginTop: 4
                          }}
                        >
                          <legend
                            style={{
                              padding: '0px 6px',
                              color: '#d95c00',
                              fontSize: 12
                            }}
                          >
                            Motivo:
                          </legend>

                          <Typography style={{ fontSize: 12, fontWeight: 500 }}>
                            {dados?.motivo}
                          </Typography>
                        </fieldset>
                      </Box>
                    </Box>
                  )
                }

                {dados.continuado && (
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: 20,
                      marginBottom: 5
                    }}
                  >
                    <Typography style={{ fontSize: 20 }}>
                      <FontAwesomeIcon
                        icon={faPlayCircle}
                        style={{ color: '#007ad9', borderRadius: 6 }}
                      />
                    </Typography>

                    <Box
                      style={{
                        width: '100%',
                        padding: 10,
                        borderRadius: 4,
                        border: '2px solid #007ad9'
                      }}
                    >
                      <Typography
                        style={{
                          color: '#444',
                          fontWeight: 'bold',
                          fontSize: 14
                        }}
                      >
                        Continuado
                      </Typography>
                      <Typography style={{ color: '#222', fontSize: 14 }}>
                        {moment(dados?.continuado).format(
                          'DD-MM-YYYY HH:mm:ss'
                        )}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </>
            );
          })}

          {detalhes?.fim && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 20,
                marginBottom: 5
              }}
            >
              <Typography style={{ fontSize: 20 }}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ color: '#24b324' }}
                />
              </Typography>

              <Box
                style={{
                  width: '100%',
                  padding: 10,
                  borderRadius: 4,
                  border: '2px solid #24b324'
                }}
              >
                <Typography
                  style={{ color: '#444', fontWeight: 'bold', fontSize: 14 }}
                >
                  Finalizado
                </Typography>
                <Typography style={{ color: '#222', fontSize: 14 }}>
                  {moment(detalhes?.fim).format('DD-MM-YYYY HH:mm:ss')}
                </Typography>
              </Box>
            </Box>
          )}
        </TabPanel>
      </DialogContent>
      
      <DialogSelecionarCliente
        open={openChangeClient}
        idCliente={idCliente ? idCliente : detalhes.id_pessoa}
        setOpen={setOpenChangeClient}
        setIdCliente={setIdCliente}
        setNomeCliente={setNomeCliente}
        idAtendimento={detalhes.id}
      />
    </Dialog>
  );
}

export default Details;

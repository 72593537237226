import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import Info from '@material-ui/icons/Info';
import Print from '@material-ui/icons/Print';
import Search from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';

import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useSnackbar } from 'notistack';

import ShowSnack from 'src/utils/snacks';

import api from 'src/services/data';

import DialogDetails from '../../Atendimentos/Components/Details';
import PrintRelatorioAtendimento from './Components/PrintRelatorioAtendimento';
import PrintRelatorioSintetico from './Components/PrintRelatorioSintetico';
import DialogSelecionarCliente from 'src/views/Atendimentos/MeusAtendimentos/components/DialogSelecionarCliente';
import DialogSelecionarFuncionario from './Components/DialogSelecionarFuncionario';
import DialogDetailsV1 from 'src/views/Atendimentos/AcompanharAtendimentosV1/Components/DetalhesV1';

import './styles.css';
import { Typography } from '@material-ui/core';

function AtendimentosV1() {
  const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));
  const permissoes = JSON.parse(localStorage.getItem('@Account#Permi#MM@'));
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [loadingSearch, setLoadingSearch] = useState(false);
  
  const [atendimentos, setAtendimentos] = useState([]);

  const [openDetails, setOpenDetails] = useState(false);
  const [idAtendimento, setIdAtendimento] = useState('');
  const [detalhesAtendimento, setDetalhesAtendimento] = useState([]);

  const [openPrevisualizacao, setOpenPrevisualizacao] = useState(false);

  const [openSelecionarCliente, setOpenSelecionarCliente] = useState(false);
  const [clienteSelecionado, setClienteSelecionado] = useState('');
  const [nomeClienteSelecionado, setNomeClienteSelecionado] = useState('');

  const [openSelecionarFuncionario, setOpenSelecionarFuncionario] = useState(false);
  const [funcionarioSelecionado, setFuncionarioSelecionado] = useState('');
  const [nomeFuncionarioSelecionado, setNomeFuncionarioSelecionado] = useState('');

  const [dataInicial, setDataInicial] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [dataFinal, setDataFinal] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [tipoRelatorio, setTipoRelatorio] = useState('generico');

  const buscarDados = () => {
    setLoadingSearch(true);

    api
      .post(
        'api/acesso/relatorio/atendimentos/v1',
        {
          cliente: clienteSelecionado,
          funcionario: funcionarioSelecionado,
          data_inicio: `${dataInicial} 00:00:00`,
          data_final: `${dataFinal} 23:59:59`
        },
        {
          headers: { Authorization: `Bearer ${sessao.lock}` }
        }
      )
      .then(({ data }) => {
        const getAtendimentos = data.map(atendimento => {
          return {
            ...atendimento,
            getSolicitante: atendimento.solicitante
              ? atendimento.solicitante
              : '-',
            btnAcoes: (
              <>
                <IconButton
                  onClick={() => {
                    setOpenDetails(true);
                    setIdAtendimento(atendimento.id);
                  }}
                >
                  <Info style={{ color: '#6420cfd9' }} />
                </IconButton>
              </>
            )
          };
        });

        setAtendimentos(getAtendimentos);
        setLoadingSearch(false);
      }).catch(() => {
        setLoadingSearch(false);
      });
  };

  const printRelatorio = () => {
    if (atendimentos.length > 0) {
      window.print();
    } else {
      ShowSnack('Sem registros!', enqueueSnackbar, 'info');
    }
  }

  useEffect(() => {
    if (!permissoes.permi.relatorio_atendimentos) {
      history.push('/app/dashboard');
      ShowSnack('Permissão não concedida', enqueueSnackbar, 'warning');
    }
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} className="area_filtros_atendimentos no_print">
        <Grid container>
          <Grid item xs={12} md={4} className="center">
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Tipo de Relatório:</FormLabel>
              <RadioGroup
                row
                value={tipoRelatorio}
                onChange={(e => setTipoRelatorio(e.target.value))}
                style={{
                  border: '1px solid #8c8c8c',
                  borderRadius: 4,
                  padding: '0 4px'
                }}
              >
                <FormControlLabel value="generico" control={<Radio />} label="Analítico" />
                <FormControlLabel value="sintetico" control={<Radio />} label="Sintético" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4} className="center">
            <label>Data De:</label>
            <input
              type="date"
              className="input_date_filter"
              value={dataInicial}
              onChange={(e) => setDataInicial(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={4} className="center">
            <label htmlFor="ate">Data Até:</label>
            <input
              type="date"
              id="ate"
              className="input_date_filter"
              value={dataFinal}
              onChange={(e) => setDataFinal(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={6} className="center">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              style={{ width: '100%' }}
            >
              <Typography variant="subtitle2">
                Cliente Selecionado:
              </Typography>
              
              {
                clienteSelecionado && (
                  <IconButton
                    style={{ padding: 0, color: '#b50000' }}
                    onClick={() => {
                      setClienteSelecionado('');
                      setNomeClienteSelecionado('');
                    }}
                  >
                    <Close />
                  </IconButton>
                )
              }
            </Box>
            <Button
              variant='contained'
              color="primary"
              fullWidth
              onClick={() => setOpenSelecionarCliente(true)}
            >
                { nomeClienteSelecionado ? nomeClienteSelecionado : 'Todos' }
            </Button>
          </Grid>

          <Grid item xs={12} md={6} className="center">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              style={{ width: '100%' }}
            >
              <Typography variant="subtitle2">
                Funcionário Selecionado:
              </Typography>
              
              {
                funcionarioSelecionado && (
                  <IconButton
                    style={{ padding: 0, color: '#b50000' }}
                    onClick={() => {
                      setFuncionarioSelecionado('');
                      setNomeFuncionarioSelecionado('');
                    }}
                  >
                    <Close />
                  </IconButton>
                )
              }
            </Box>
            <Button
              variant='contained'
              color="primary"
              fullWidth
              onClick={() => setOpenSelecionarFuncionario(true)}
            >
                { nomeFuncionarioSelecionado ? nomeFuncionarioSelecionado : 'Todos' }
            </Button>
          </Grid>
          
          <Grid item xs={12} className="area_btn_relatorio">
            <Button
              variant="contained"
              color="primary"
              startIcon={<Search />}
              onClick={() => {
                buscarDados();
              }}
            >
              Buscar
            </Button>

            <Button
              variant="contained"
              style={{ background: '#b00f14', color: '#fff' }}
              startIcon={<Print />}
              onClick={() => {
                if (atendimentos.length > 0) {
                  setOpenPrevisualizacao(true);
                } else {
                  ShowSnack('Sem registros!', enqueueSnackbar, 'info');
                }
              }}
            >
              Visualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      
      <Grid item xs={12}>
        {
          loadingSearch ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <DataTable
              paginator
              rows={10}
              responsive
              emptyMessage="Nenhum registro encontrado..."
              style={{ textAlign: 'center', fontSize: 14 }}
              value={atendimentos}
            >
              <Column
                field="protocolo"
                header="Nº de Protocolo"
                filter
                filterMatchMode="contains"
              />
              <Column
                field="nome_cliente"
                header="Cliente"
                filter
                filterMatchMode="contains"
              />
              <Column
                field="getSolicitante"
                header="Solicitante"
                filter
                filterMatchMode="contains"
              />
              <Column
                field="nome_user"
                header="Atendente"
                filter
                filterMatchMode="contains"
              />
              <Column field="btnAcoes" header="Ações" />
            </DataTable>
          )
        }
      </Grid>
      

      <Dialog
        open={openPrevisualizacao}
        onClose={() => {
          setOpenPrevisualizacao(false);
        }}
        fullScreen
      >
        <DialogContent>
          <Box className="no_print">
            <Button variant="contained" color="primary" onClick={() => printRelatorio()}>Imprimir</Button>
            <Button variant="outlined" color="secondary" onClick={() => setOpenPrevisualizacao(false)}>Fechar Visualização</Button>
          </Box>

          <Box>
            {
              tipoRelatorio === 'generico' ? (
                <PrintRelatorioAtendimento atendimentos={atendimentos} />
              ) : (
                <PrintRelatorioSintetico atendimentos={atendimentos} />
              )
            }
          </Box>
        </DialogContent>
      </Dialog>

      <DialogDetailsV1
        open={openDetails}
        idAtendimento={idAtendimento}
        setOpen={setOpenDetails}
        setIdAtendimento={setIdAtendimento}
      />

      <DialogSelecionarCliente
        open={openSelecionarCliente}
        setOpen={setOpenSelecionarCliente}
        setIdCliente={setClienteSelecionado}
        setNomeCliente={setNomeClienteSelecionado}
        isVersioOne
      />

      <DialogSelecionarFuncionario
        open={openSelecionarFuncionario}
        setOpen={setOpenSelecionarFuncionario}
        setIdFuncionario={setFuncionarioSelecionado}
        setNomeFuncionario={setNomeFuncionarioSelecionado}
        isVersioOne
      />
    </Grid>
  );
}

export default AtendimentosV1;

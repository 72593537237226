import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';

import SwapHoriz from '@material-ui/icons/SwapHoriz';
import List from '@material-ui/icons/List';
import Description from '@material-ui/icons/Description';

import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';
import api from 'src/services/data';

import BoxPermissoes from '../components/BoxPermissoes';

export default function PerfisFormEdit() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));
  const permissoes = JSON.parse(localStorage.getItem('@Account#Permi#MM@'));
  const [dateCad, setDateCad] = useState('');

  const [descricao, setDescricao] = useState('');
  const [visPessoa, setVisPessoa] = useState(false);
  const [cadPessoa, setCadPessoa] = useState(false);
  const [ediPessoa, setEdiPessoa] = useState(false);
  const [delPessoa, setDelPessoa] = useState(false);
  const [visGrupops, setVisGrupops] = useState(false);
  const [cadGrupops, setCadGrupops] = useState(false);
  const [ediGrupops, setEdiGrupops] = useState(false);
  const [delGrupops, setDelGrupops] = useState(false);
  const [visSubgrupops, setVisSubgrupops] = useState(false);
  const [cadSubgrupops, setCadSubgrupops] = useState(false);
  const [ediSubgrupops, setEdiSubgrupops] = useState(false);
  const [delSubgrupops, setDelSubgrupops] = useState(false);
  const [visProdutos, setVisProdutos] = useState(false);
  const [cadProdutos, setCadProdutos] = useState(false);
  const [ediProdutos, setEdiProdutos] = useState(false);
  const [delProdutos, setDelProdutos] = useState(false);
  const [visGruposs, setVisGruposs] = useState(false);
  const [cadGruposs, setCadGruposs] = useState(false);
  const [ediGruposs, setEdiGruposs] = useState(false);
  const [delGruposs, setDelGruposs] = useState(false);
  const [visServicos, setVisServicos] = useState(false);
  const [cadServicos, setCadServicos] = useState(false);
  const [ediServicos, setEdiServicos] = useState(false);
  const [delServicos, setDelServicos] = useState(false);
  const [visPerfis, setVisPerfis] = useState(false);
  const [cadPerfis, setCadPerfis] = useState(false);
  const [ediPerfis, setEdiPerfis] = useState(false);
  const [delPerfis, setDelPerfis] = useState(false);
  const [visUsuarios, setVisUsuarios] = useState(false);
  const [cadUsuarios, setCadUsuarios] = useState(false);
  const [ediUsuarios, setEdiUsuarios] = useState(false);
  const [delUsuarios, setDelUsuarios] = useState(false);

  {
    /* Movimentações */
  }
  const [novoAtendimentos, setNovoAtendimentos] = useState(false);
  const [pausarAtendimentos, setPausarAtendimentos] = useState(false);
  const [continuarAtendimentos, setContinuarAtendimentos] = useState(false);
  const [finalizarAtendimentos, setFinalizarAtendimentos] = useState(false);
  const [emAtendimentoAtendimentos, setEmAtendimentoAtendimentos] = useState(
    false
  );
  const [finalizadosAtendimentos, setFinalizadosAtendimentos] = useState(false);
  const [acompanharAtendimentos, setAcompanharAtendimentos] = useState(false);

  {
    /* Relatórios */
  }
  const [relatorioAtendimentos, setRelatorioAtendimentos] = useState(false);

  const [selectTodos, setSelectTodos] = useState(false);

  useEffect(() => {
    if (!permissoes.permi.edi_perfis) {
      history.push('/app/dashboard');
      ShowSnack('Permissão não concedida', enqueueSnackbar, 'warning');
    }
  }, []);

  useEffect(() => {
    if (
      visPessoa &&
      cadPessoa &&
      ediPessoa &&
      delPessoa &&
      visGrupops &&
      cadGrupops &&
      ediGrupops &&
      delGrupops &&
      visSubgrupops &&
      cadSubgrupops &&
      ediSubgrupops &&
      delSubgrupops &&
      visProdutos &&
      cadProdutos &&
      ediProdutos &&
      delProdutos &&
      visGruposs &&
      cadGruposs &&
      ediGruposs &&
      delGruposs &&
      visServicos &&
      cadServicos &&
      ediServicos &&
      delServicos &&
      visPerfis &&
      cadPerfis &&
      ediPerfis &&
      delPerfis &&
      visUsuarios &&
      cadUsuarios &&
      ediUsuarios &&
      delUsuarios &&
      novoAtendimentos &&
      pausarAtendimentos &&
      continuarAtendimentos &&
      finalizarAtendimentos &&
      emAtendimentoAtendimentos &&
      finalizadosAtendimentos &&
      acompanharAtendimentos &&
      relatorioAtendimentos
    ) {
      setSelectTodos(true);
    } else {
      setSelectTodos(false);
    }
  }, [
    visPessoa,
    cadPessoa,
    ediPessoa,
    delPessoa,
    visGrupops,
    cadGrupops,
    ediGrupops,
    delGrupops,
    visSubgrupops,
    cadSubgrupops,
    ediSubgrupops,
    delSubgrupops,
    visProdutos,
    cadProdutos,
    ediProdutos,
    delProdutos,
    visGruposs,
    cadGruposs,
    ediGruposs,
    delGruposs,
    visServicos,
    cadServicos,
    ediServicos,
    delServicos,
    visPerfis,
    cadPerfis,
    ediPerfis,
    delPerfis,
    visUsuarios,
    cadUsuarios,
    ediUsuarios,
    delUsuarios,
    novoAtendimentos,
    pausarAtendimentos,
    continuarAtendimentos,
    finalizarAtendimentos,
    emAtendimentoAtendimentos,
    finalizadosAtendimentos,
    acompanharAtendimentos,
    relatorioAtendimentos
  ]);

  useEffect(() => {
    setLoading(true);

    api
      .get(`api/acesso/lista_one/perfil/${id}`, {
        headers: { Authorization: `Bearer ${sessao.lock}` }
      })
      .then(({ data }) => {
        setDescricao(data.nome);
        setVisPessoa(data.vis_pessoas);
        setCadPessoa(data.cad_pessoas);
        setEdiPessoa(data.edi_pessoas);
        setDelPessoa(data.del_pessoas);
        setVisGrupops(data.vis_grupops);
        setCadGrupops(data.cad_grupops);
        setEdiGrupops(data.edi_grupops);
        setDelGrupops(data.del_grupops);
        setVisSubgrupops(data.vis_subgrupops);
        setCadSubgrupops(data.cad_subgrupops);
        setEdiSubgrupops(data.edi_subgrupops);
        setDelSubgrupops(data.del_subgrupops);
        setVisProdutos(data.vis_produtos);
        setCadProdutos(data.cad_produtos);
        setEdiProdutos(data.edi_produtos);
        setDelProdutos(data.del_produtos);
        setVisGruposs(data.vis_gruposs);
        setCadGruposs(data.cad_gruposs);
        setEdiGruposs(data.edi_gruposs);
        setDelGruposs(data.del_gruposs);
        setVisServicos(data.vis_servicos);
        setCadServicos(data.cad_servicos);
        setEdiServicos(data.edi_servicos);
        setDelServicos(data.del_servicos);
        setVisPerfis(data.vis_perfis);
        setCadPerfis(data.cad_perfis);
        setEdiPerfis(data.edi_perfis);
        setDelPerfis(data.del_perfis);
        setVisUsuarios(data.vis_usuarios);
        setCadUsuarios(data.cad_usuarios);
        setEdiUsuarios(data.edi_usuarios);
        setDelUsuarios(data.del_usuarios);
        setNovoAtendimentos(data.novo_atendimentos);
        setPausarAtendimentos(data.pausar_atendimentos);
        setContinuarAtendimentos(data.continuar_atendimentos);
        setFinalizarAtendimentos(data.finalizar_atendimentos);
        setEmAtendimentoAtendimentos(data.ematendimento_atendimentos);
        setFinalizadosAtendimentos(data.finalizados_atendimentos);
        setAcompanharAtendimentos(data.acompanhar_atendimentos);
        setRelatorioAtendimentos(data.relatorio_atendimentos);

        let cad = new Date(data.created_at).toISOString().split('T')[0].split('-');
        cad = `${cad[2]}/${cad[1]}/${cad[0]}`;

        setDateCad(cad);

        setLoading(false);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  const todos = valor => {
    setSelectTodos(valor);
    let selecionou = false;
    if (valor) {
      selecionou = true;
    }

    setVisPessoa(selecionou);
    setCadPessoa(selecionou);
    setEdiPessoa(selecionou);
    setDelPessoa(selecionou);
    setVisGrupops(selecionou);
    setCadGrupops(selecionou);
    setEdiGrupops(selecionou);
    setDelGrupops(selecionou);
    setVisSubgrupops(selecionou);
    setCadSubgrupops(selecionou);
    setEdiSubgrupops(selecionou);
    setDelSubgrupops(selecionou);
    setVisProdutos(selecionou);
    setCadProdutos(selecionou);
    setEdiProdutos(selecionou);
    setDelProdutos(selecionou);
    setVisGruposs(selecionou);
    setCadGruposs(selecionou);
    setEdiGruposs(selecionou);
    setDelGruposs(selecionou);
    setVisServicos(selecionou);
    setCadServicos(selecionou);
    setEdiServicos(selecionou);
    setDelServicos(selecionou);
    setVisPerfis(selecionou);
    setCadPerfis(selecionou);
    setEdiPerfis(selecionou);
    setDelPerfis(selecionou);
    setVisUsuarios(selecionou);
    setCadUsuarios(selecionou);
    setEdiUsuarios(selecionou);
    setDelUsuarios(selecionou);
    setNovoAtendimentos(selecionou);
    setPausarAtendimentos(selecionou);
    setContinuarAtendimentos(selecionou);
    setFinalizarAtendimentos(selecionou);
    setEmAtendimentoAtendimentos(selecionou);
    setFinalizadosAtendimentos(selecionou);
    setAcompanharAtendimentos(selecionou);
    setRelatorioAtendimentos(selecionou);
  };

  const salvar = () => {
    if (
      descricao &&
      (visPessoa ||
        cadPessoa ||
        ediPessoa ||
        delPessoa ||
        visGrupops ||
        cadGrupops ||
        ediGrupops ||
        delGrupops ||
        visSubgrupops ||
        cadSubgrupops ||
        ediSubgrupops ||
        delSubgrupops ||
        visProdutos ||
        cadProdutos ||
        ediProdutos ||
        delProdutos ||
        visGruposs ||
        cadGruposs ||
        ediGruposs ||
        delGruposs ||
        visServicos ||
        cadServicos ||
        ediServicos ||
        delServicos ||
        visPerfis ||
        cadPerfis ||
        ediPerfis ||
        delPerfis ||
        visUsuarios ||
        cadUsuarios ||
        ediUsuarios ||
        delUsuarios ||
        novoAtendimentos ||
        pausarAtendimentos ||
        continuarAtendimentos ||
        finalizarAtendimentos ||
        emAtendimentoAtendimentos ||
        finalizadosAtendimentos ||
        acompanharAtendimentos ||
        relatorioAtendimentos)
    ) {
      api
        .put(
          `api/acesso/atualizar/perfil/${id}`,
          {
            descricao,
            visPessoa,
            cadPessoa,
            ediPessoa,
            delPessoa,
            visGrupops,
            cadGrupops,
            ediGrupops,
            delGrupops,
            visSubgrupops,
            cadSubgrupops,
            ediSubgrupops,
            delSubgrupops,
            visProdutos,
            cadProdutos,
            ediProdutos,
            delProdutos,
            visGruposs,
            cadGruposs,
            ediGruposs,
            delGruposs,
            visServicos,
            cadServicos,
            ediServicos,
            delServicos,
            visPerfis,
            cadPerfis,
            ediPerfis,
            delPerfis,
            visUsuarios,
            cadUsuarios,
            ediUsuarios,
            delUsuarios,
            novoAtendimentos,
            pausarAtendimentos,
            continuarAtendimentos,
            finalizarAtendimentos,
            emAtendimentoAtendimentos,
            finalizadosAtendimentos,
            acompanharAtendimentos,
            relatorioAtendimentos
          },
          {
            headers: { Authorization: `Bearer ${sessao.lock}` }
          }
        )
        .then(({ data }) => {
          ShowSnack(data.message, enqueueSnackbar);
          history.push('/app/perfis-list');
        })
        .catch(e => {
          ShowSnack(e.response.data.message, enqueueSnackbar, 'error');
        });
    } else {
      ShowSnack(
        'Por favor, preencha todos campos!',
        enqueueSnackbar,
        'warning'
      );
    }
  };

  return (
    <>
      <Container className="area-title">
        <Grid container>
          <Grid item xs={12} className="grid-title">
            <Typography className="title">
              Formulário de Atualizar Perfil
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container className="area-components">
        <Grid container>
          {loading ? (
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={4} style={{ padding: 5 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={dateCad}
                  disabled
                  label="Data de Cadastrado"
                />
              </Grid>

              <Grid item xs={12} md={6} style={{ padding: 5 }}>
                <TextField
                  variant="outlined"
                  label="Nome:"
                  fullWidth
                  value={descricao}
                  onChange={e => {
                    setDescricao(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={2} style={{ padding: 5 }}>
                <Box
                  style={{
                    border: '1px solid #08318e',
                    padding: 8,
                    borderRadius: 4
                  }}
                >
                  <FormControl>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={selectTodos}
                            onChange={() => {
                              todos(!selectTodos);
                            }}
                            name="Visualizar"
                          />
                        }
                        label="Todos"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  style={{
                    color: '#013494',
                    padding: '0px 8px',
                    margin: '8px 0px',
                    fontWeight: 'bold',
                    borderBottom: '1px solid #013494',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <List />
                  &nbsp; Cadastros
                </Typography>
              </Grid>

              <BoxPermissoes
                title="Pessoas"
                valueVis={visPessoa}
                mudarValueVis={setVisPessoa}
                valueCad={cadPessoa}
                mudarValueCad={setCadPessoa}
                valueEdi={ediPessoa}
                mudarValueEdi={setEdiPessoa}
                valueDel={delPessoa}
                mudarValueDel={setDelPessoa}
              />
              <BoxPermissoes
                title="Grupos - Produtos"
                valueVis={visGrupops}
                mudarValueVis={setVisGrupops}
                valueCad={cadGrupops}
                mudarValueCad={setCadGrupops}
                valueEdi={ediGrupops}
                mudarValueEdi={setEdiGrupops}
                valueDel={delGrupops}
                mudarValueDel={setDelGrupops}
              />
              <BoxPermissoes
                title="Subgrupos"
                valueVis={visSubgrupops}
                mudarValueVis={setVisSubgrupops}
                valueCad={cadSubgrupops}
                mudarValueCad={setCadSubgrupops}
                valueEdi={ediSubgrupops}
                mudarValueEdi={setEdiSubgrupops}
                valueDel={delSubgrupops}
                mudarValueDel={setDelSubgrupops}
              />
              <BoxPermissoes
                title="Produtos"
                valueVis={visProdutos}
                mudarValueVis={setVisProdutos}
                valueCad={cadProdutos}
                mudarValueCad={setCadProdutos}
                valueEdi={ediProdutos}
                mudarValueEdi={setEdiProdutos}
                valueDel={delProdutos}
                mudarValueDel={setDelProdutos}
              />
              <BoxPermissoes
                title="Grupos - Serviços"
                valueVis={visGruposs}
                mudarValueVis={setVisGruposs}
                valueCad={cadGruposs}
                mudarValueCad={setCadGruposs}
                valueEdi={ediGruposs}
                mudarValueEdi={setEdiGruposs}
                valueDel={delGruposs}
                mudarValueDel={setDelGruposs}
              />
              <BoxPermissoes
                title="Serviços"
                valueVis={visServicos}
                mudarValueVis={setVisServicos}
                valueCad={cadServicos}
                mudarValueCad={setCadServicos}
                valueEdi={ediServicos}
                mudarValueEdi={setEdiServicos}
                valueDel={delServicos}
                mudarValueDel={setDelServicos}
              />
              <BoxPermissoes
                title="Perfis"
                valueVis={visPerfis}
                mudarValueVis={setVisPerfis}
                valueCad={cadPerfis}
                mudarValueCad={setCadPerfis}
                valueEdi={ediPerfis}
                mudarValueEdi={setEdiPerfis}
                valueDel={delPerfis}
                mudarValueDel={setDelPerfis}
              />
              <BoxPermissoes
                title="Usuários"
                valueVis={visUsuarios}
                mudarValueVis={setVisUsuarios}
                valueCad={cadUsuarios}
                mudarValueCad={setCadUsuarios}
                valueEdi={ediUsuarios}
                mudarValueEdi={setEdiUsuarios}
                valueDel={delUsuarios}
                mudarValueDel={setDelUsuarios}
              />

              <Grid item xs={12}>
                <Typography
                  style={{
                    color: '#013494',
                    padding: '0px 8px',
                    margin: '8px 0px',
                    fontWeight: 'bold',
                    borderBottom: '1px solid #013494',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <SwapHoriz />
                  &nbsp; Movimentações
                </Typography>
              </Grid>

              {/* Atendimentos */}
              <Grid item xs={12} md={3} style={{ padding: 4 }}>
                <Box
                  style={{
                    border: '1px solid #08318e',
                    padding: 10,
                    borderRadius: 4
                  }}
                >
                  <FormControl>
                    <FormLabel>
                      <Typography
                        style={{
                          color: '#08318e',
                          fontSize: 16,
                          fontWeight: 'bold'
                        }}
                      >
                        Atendimentos
                      </Typography>
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={novoAtendimentos}
                            onChange={e => {
                              setNovoAtendimentos(e.target.checked);
                            }}
                            name="Novo"
                          />
                        }
                        label="Novo"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={pausarAtendimentos}
                            onChange={e => {
                              setPausarAtendimentos(e.target.checked);
                            }}
                            name="Pausar"
                          />
                        }
                        label="Pausar"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={continuarAtendimentos}
                            onChange={e => {
                              setContinuarAtendimentos(e.target.checked);
                            }}
                            name="Continuar"
                          />
                        }
                        label="Continuar"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={finalizarAtendimentos}
                            onChange={e => {
                              setFinalizarAtendimentos(e.target.checked);
                            }}
                            name="Finalizar"
                          />
                        }
                        label="Finalizar"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={emAtendimentoAtendimentos}
                            onChange={e => {
                              setEmAtendimentoAtendimentos(e.target.checked);
                            }}
                            name="Em Atendimento"
                          />
                        }
                        label="Em Atendimento"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={finalizadosAtendimentos}
                            onChange={e => {
                              setFinalizadosAtendimentos(e.target.checked);
                            }}
                            name="Finalizados"
                          />
                        }
                        label="Finalizados"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={acompanharAtendimentos}
                            onChange={e => {
                              setAcompanharAtendimentos(e.target.checked);
                            }}
                            name="Acompanhar Atendimentos"
                          />
                        }
                        label="Acompanhar Atendimentos"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  style={{
                    color: '#013494',
                    padding: '0px 8px',
                    margin: '8px 0px',
                    fontWeight: 'bold',
                    borderBottom: '1px solid #013494',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Description />
                  &nbsp; Relatórios
                </Typography>
              </Grid>

              <Grid item xs={12} md={3} style={{ padding: 4 }}>
                <Box
                  style={{
                    border: '1px solid #08318e',
                    padding: 10,
                    borderRadius: 4
                  }}
                >
                  <FormControl>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={relatorioAtendimentos}
                            onChange={e => {
                              setRelatorioAtendimentos(e.target.checked);
                            }}
                            name="Atendimentos"
                          />
                        }
                        label="Atendimentos"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} style={{ padding: 10, textAlign: 'center' }}>
                <Button
                  className="button-register"
                  onClick={() => {
                    salvar();
                  }}
                >
                  Salvar
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}

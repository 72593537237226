import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Button,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { PowerSettingsNew, Settings } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ShowSnack from '../../utils/snacks';
import api from '../../services/data';

import { getTimeSession } from 'src/utils/getTimeSession';
import DialogReloadSession from '../../components/DialogReloadSession'

import Logo from 'src/components/Logo';
import Logout from './components/Logout';

const useStyles = makeStyles(() => ({
  root: {
    background: '#013494'
  },
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));

  const [timeSession, setTimeSession] = useState('');

  const [openLogout, setOpenLogout] = useState(false);
  const [openReloadSession, setOpenReloadSession] = useState(false);

  const accessBlocked = () => {
    localStorage.removeItem('@N#P#U@');
    localStorage.removeItem('@Account#Sessio#MM@');
    localStorage.removeItem('@Account#Remem#MM@');
    localStorage.removeItem('@Account#Permi#MM@');
    localStorage.removeItem('notify');
    history.push('/');
    ShowSnack('Acesso Negado!', enqueueSnackbar, 'error');
  };

  const noficationWeb = () => {
    if (JSON.parse(localStorage.getItem('notify'))?.actived) {
      return;
    }
    
    localStorage.setItem('notify', JSON.stringify({ actived: 'ok' }))

    new Audio('/notification.mp3').play();

    if (Notification.permission === 'granted') {
        new Notification('Tempo de sessão expirando!', {
            body: `Confirme o seu acesso para continuar navegando.`
        })
    }
  }

  useEffect(() => {
    Notification.requestPermission();
  }, []);

  useEffect(() => {
    if (sessao?.lock) {
      api
        .post(
          'api/auth/auth',
          {},
          {
            headers: { Authorization: `Bearer ${sessao.lock}` }
          }
        )
        .then(({ data }) => {
          if (data.status != 'ok') {
            accessBlocked();
          }
        })
        .catch(() => {
          accessBlocked();
        });
    } else {
      accessBlocked();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const {timeFormat, reloadSession} = getTimeSession(sessao.time_session);
      
      if(reloadSession){
       noficationWeb();
      }

      const arrayTimeFormat = timeFormat.split(':');

      if (arrayTimeFormat[1] <= 0 && arrayTimeFormat[2] >= 0) {
        accessBlocked();
        return;
      }

      setOpenReloadSession(reloadSession);
      setTimeSession(timeFormat);

    }, 1000);
  }, [timeSession]);

  return (
    <>
      <AppBar className={`${clsx(classes.root, className)}`} elevation={0} {...rest}>
        <Toolbar>
          <RouterLink to="/">
            <Logo />
          </RouterLink>

          <fieldset
            style={{
              borderRadius: 4,
              marginLeft: 20,
              padding: '0px 4px'
            }}
          >
            <legend
              style={{
                fontSize: 10,
                fontFamily: 'sans-serif',
                fontWeight: 600,
                padding: '0px 4px'
              }}
            >
              Sessão:
            </legend>

            <Typography style={{ color: '#fff', fontSize: 18, textAlign: 'right' }}>
              {timeSession}
            </Typography>
          </fieldset>
          <Box flexGrow={1} />
          <Hidden mdDown>
            <IconButton
              color="inherit"
              title="Configurações"
              onClick={() => {
                history.push('/app/atualizar-dados');
              }}
            >
              <Settings />
            </IconButton>

            <IconButton
              color="inherit"
              title="Sair da Plataforma"
              onClick={() => {
                setOpenLogout(true);
              }}
            >
              <PowerSettingsNew />
            </IconButton>
          </Hidden>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>

      <Logout open={openLogout} setOpen={setOpenLogout} />

      <DialogReloadSession open={openReloadSession} setOpen={setOpenReloadSession} />
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;

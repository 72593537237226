import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';

import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Info from '@material-ui/icons/Info';

import { Alert } from '@material-ui/lab';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';
import api from '../../../services/data';

import DetalhesPermissoes from '../components/DetalhesPermissoes';

export default function PerfisList() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const sessao = JSON.parse(localStorage.getItem('@Account#Sessio#MM@'));
  const permissoes = JSON.parse(localStorage.getItem('@Account#Permi#MM@'));

  const [perfis, setPerfis] = useState([]);

  const [open, setOpen] = useState(false);
  const [openDetalhes, setOpenDetalhes] = useState(false);

  const [id, setId] = useState('');
  const [deletado, setDeletado] = useState(false);
  const [detalhes, setDetalhes] = useState([]);

  const del = () => {
    setOpen(false);
    api
      .delete(`/api/acesso/deletar/perfil/${id}`, {
        headers: { Authorization: `Bearer ${sessao.lock}` }
      })
      .then(({ data }) => {
        ShowSnack(data.message, enqueueSnackbar);
        setDeletado(!deletado);
      })
      .catch(e => {
        ShowSnack(e.response.data.message, enqueueSnackbar, 'error');
      });
  };

  useEffect(() => {
    if (
      permissoes.permi.vis_perfis != 1 &&
      permissoes.permi.cad_perfis != 1 &&
      permissoes.permi.edi_perfis != 1 &&
      permissoes.permi.del_perfis != 1 &&
      permissoes.permi.vis_perfis != 1
    ) {
      history.push('/app/dashboard');
      ShowSnack('Permissão não concedida', enqueueSnackbar, 'warning');
    }
  }, []);

  useEffect(() => {
    api
      .get('api/acesso/lista/perfis', {
        headers: { Authorization: `Bearer ${sessao.lock}` }
      })
      .then(({ data }) => {
        const results = data.map(dados => {
          return {
            nome: dados.nome,
            btnAcoes: (
              <>
                <IconButton
                  title="Mais Detalhes"
                  style={{ color: '#6420cfd9' }}
                  onClick={() => {
                    setDetalhes(dados);
                    setOpenDetalhes(true);
                  }}
                >
                  <Info />
                </IconButton>

                {permissoes.permi.edi_perfis === 1 && (
                  <IconButton
                    title="Atualizar Informações"
                    style={{ color: '#e26912' }}
                    onClick={() => {
                      history.push(`/app/perfis-form-edit/${dados.id}`);
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}

                {permissoes.permi.del_perfis === 1 && (
                  <IconButton
                    title="Excluir"
                    style={{ color: '#bc0910' }}
                    onClick={() => {
                      setId(dados.id);
                      setOpen(true);
                    }}
                  >
                    <Delete />
                  </IconButton>
                )}
              </>
            )
          };
        });

        setPerfis(results);
      })
      .catch(e => {
        console.log(e);
      });
  }, [deletado]);

  return (
    <>
      <Container>
        <Grid container className="area-title">
          <Grid item xs={12} className="grid-title">
            <Typography className="title">Listagem de perfis</Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container>
          {permissoes.permi.cad_perfis === 1 && (
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                className="btnPrimary"
                onClick={() => {
                  history.push('/app/perfis-form');
                }}
              >
                <Add />
                &nbsp;Novo
              </Button>
            </Grid>
          )}

          <Grid item xs={12}>
            {permissoes.permi.vis_perfis === 1 ? (
              <DataTable
                value={perfis}
                paginator
                rows={10}
                responsive
                emptyMessage="Nenhum registro cadastrado..."
                style={{ textAlign: 'center' }}

                rowHover
                currentPageReportTemplate="Mostrando {first} ao {last} de {totalRecords} registros"
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport "
                rowsPerPageOptions={[10,25,50]}
              >
                <Column field="nome" header="Nome" filter></Column>
                <Column field="btnAcoes" header="Ações"></Column>
              </DataTable>
            ) : (
              <Typography
                style={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  marginTop: 10,
                  fontWeight: 'bold',
                  padding: 10,
                  width: '100%',
                  background: '#d09d0f',
                  color: '#222',
                  borderRadius: 4,
                  fontSize: 20
                }}
              >
                Para editar e excluir é necessário a permissão de visualizar
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle style={{ background: '#08318e' }}>
          <Typography
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 25,
              textAlign: 'center'
            }}
          >
            Confirmar Exclução
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: 16,
              fontWeight: 555,
              marginBottom: 10
            }}
          >
            Você realmente deseja excluir estes dados?
          </Typography>
          <Alert variant="filled" severity="warning">
            Ao confirmar todos estes dados serão excluídos e não poderão ser
            recuperados.
          </Alert>
        </DialogContent>

        <DialogActions className="footer-dialog-cad-unidade">
          <Button
            onClick={() => {
              del();
            }}
            color="primary"
            className="button-register"
          >
            Confirmar
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            className="button-back"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDetalhes}
        onClose={() => {
          setOpenDetalhes(false);
        }}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ background: '#08318e' }}>
          <Typography
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 25,
              textAlign: 'center'
            }}
          >
            + Informações
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Box className="boxInfo">
            <Typography className="textInfo">Cadastrado em:</Typography>

            <Typography className="fontInfo">{moment(detalhes.created_at).format('DD/MM/YYYY HH:mm')}</Typography>
          </Box>

          <Box className="boxInfo">
            <Typography className="textInfo">Nome:</Typography>

            <Typography className="fontInfo">{detalhes.nome}</Typography>
          </Box>

          <DetalhesPermissoes
            nome="Pessoas"
            vis={detalhes.vis_pessoas}
            cad={detalhes.cad_pessoas}
            edi={detalhes.edi_pessoas}
            del={detalhes.del_pessoas}
          />

          <DetalhesPermissoes
            nome="Grupos - Produto"
            vis={detalhes.vis_grupops}
            cad={detalhes.cad_grupops}
            edi={detalhes.edi_grupops}
            del={detalhes.del_grupops}
          />

          <DetalhesPermissoes
            nome="Subgrupos"
            vis={detalhes.vis_subgrupops}
            cad={detalhes.cad_subgrupops}
            edi={detalhes.edi_subgrupops}
            del={detalhes.del_subgrupops}
          />

          <DetalhesPermissoes
            nome="Produtos"
            vis={detalhes.vis_produtos}
            cad={detalhes.cad_produtos}
            edi={detalhes.edi_produtos}
            del={detalhes.del_produtos}
          />

          <DetalhesPermissoes
            nome="Grupos - Serviços"
            vis={detalhes.vis_gruposs}
            cad={detalhes.cad_gruposs}
            edi={detalhes.edi_gruposs}
            del={detalhes.del_gruposs}
          />

          <DetalhesPermissoes
            nome="Serviços"
            vis={detalhes.vis_servicos}
            cad={detalhes.cad_servicos}
            edi={detalhes.edi_servicos}
            del={detalhes.del_servicos}
          />

          <DetalhesPermissoes
            nome="Perfis"
            vis={detalhes.vis_perfis}
            cad={detalhes.cad_perfis}
            edi={detalhes.edi_perfis}
            del={detalhes.del_perfis}
          />

          <DetalhesPermissoes
            nome="Usuários"
            vis={detalhes.vis_usuarios}
            cad={detalhes.cad_usuarios}
            edi={detalhes.edi_usuarios}
            del={detalhes.del_usuarios}
          />

          <DetalhesPermissoes
            nome="Usuários"
            vis={detalhes.vis_usuarios}
            cad={detalhes.cad_usuarios}
            edi={detalhes.edi_usuarios}
            del={detalhes.del_usuarios}
          />

          {/* Atendimentos */}
          <Box className="boxInfoTwo">
            <Box style={{ borderBottom: '1px dashed #08318e' }}>
              <Typography
                style={{ color: '#08318e', fontSize: 16, fontWeight: 'bold' }}
              >
                Atendimentos
              </Typography>
            </Box>

            <Box className="boxInfo">
              <Typography className="textInfo">Novo:</Typography>
              <Typography className="fontInfo">
                {detalhes.novo_atendimentos ? 'Permitido' : 'Negado'}
              </Typography>
            </Box>

            <Box className="boxInfo">
              <Typography className="textInfo">Pausar:</Typography>
              <Typography className="fontInfo">
                {detalhes.pausar_atendimentos ? 'Permitido' : 'Negado'}
              </Typography>
            </Box>

            <Box className="boxInfo">
              <Typography className="textInfo">Continuar:</Typography>
              <Typography className="fontInfo">
                {detalhes.continuar_atendimentos ? 'Permitido' : 'Negado'}
              </Typography>
            </Box>

            <Box className="boxInfo">
              <Typography className="textInfo">Finalizar:</Typography>
              <Typography className="fontInfo">
                {detalhes.finalizar_atendimentos ? 'Permitido' : 'Negado'}
              </Typography>
            </Box>

            <Box className="boxInfo">
              <Typography className="textInfo">Em Atendimento:</Typography>
              <Typography className="fontInfo">
                {detalhes.ematendimento_atendimentos ? 'Permitido' : 'Negado'}
              </Typography>
            </Box>

            <Box className="boxInfo">
              <Typography className="textInfo">Finalizados:</Typography>
              <Typography className="fontInfo">
                {detalhes.finalizados_atendimentos ? 'Permitido' : 'Negado'}
              </Typography>
            </Box>

            <Box className="boxInfo">
              <Typography className="textInfo">Acompanhar:</Typography>
              <Typography className="fontInfo">
                {detalhes.acompanhar_atendimentos ? 'Permitido' : 'Negado'}
              </Typography>
            </Box>
          </Box>

          {/* Relatórios */}
          <Box className="boxInfoTwo">
            <Box style={{ borderBottom: '1px dashed #08318e' }}>
              <Typography
                style={{ color: '#08318e', fontSize: 16, fontWeight: 'bold' }}
              >
                Relatórios
              </Typography>
            </Box>

            <Box className="boxInfo">
              <Typography className="textInfo">Atendimentos:</Typography>
              <Typography className="fontInfo">
                {detalhes.relatorio_atendimentos ? 'Permitido' : 'Negado'}
              </Typography>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions className="footer-dialog-cad-unidade">
          <Button
            onClick={() => {
              setOpenDetalhes(false);
            }}
            color="primary"
            className="button-back"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
